import {
    useController,
    FieldValues,
    FieldPath,
    Controller,
} from 'react-hook-form';

import { ControlledInputProps } from '../../types/ControlledInput';
import Colors from '../../constants/Colors';
import { View, StyleSheet, Text, Pressable, ViewStyle } from 'react-native';
import Theme from '../../constants/Theme';

export type RadioButtonProps = {
    groupLabel?: string;
    style?: ViewStyle;
};

/**
 * A controlled radio button component that uses react-hook-form on top of
 * a custom radio button component also defined here.
 */
export default function CtlRadioButton<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & RadioButtonProps) {
    const {
        field,
        fieldState: { error },
    } = useController<TFieldValues, TName>({
        name,
        control,
        rules,
    });

    const { groupLabel, style: groupStyle } = rest; // Style renamed to avoid conflicts

    const styles = StyleSheet.create({
        container: {
            marginTop: 20,
            flexDirection: 'column',
            backgroundColor: '#fff',
            textAlign: 'left',
        },
        groupLabel: {
            fontSize: 14,
            fontWeight: '500',
        },
        buttonsContainer: {
            flexDirection: 'row',
            textAlign: 'center',
        },
        radioButton: {
            borderRadius: 50,
            borderWidth: 2.5,
            borderColor: Colors.theme.lightGray,
            justifyContent: 'center',
            alignContent: 'center',
            marginVertical: 10,
            width: 100,
            height: 30,
        },
        radioButtonSelected: {
            backgroundColor: '#000',
            borderColor: Colors.theme.black,
        },
        buttonText: {
            fontSize: 15,
            fontWeight: '600',
            textAlign: 'center',
            color: Colors.theme.lightGray,
        },
        buttonTextSelected: {
            color: Colors.theme.white,
        },
        buttonRight: {
            marginLeft: 10,
        },
        errorMsg: {
            color: Colors.theme.error,
        },
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
                <View
                    style={StyleSheet.flatten([Theme.pageContent, groupStyle])}
                >
                    <Text style={styles.groupLabel}>{groupLabel}</Text>
                    <View style={styles.buttonsContainer}>
                        <Pressable
                            style={StyleSheet.flatten([
                                styles.radioButton,
                                value ? styles.radioButtonSelected : null,
                            ])}
                            onPress={() => onChange(true)}
                        >
                            <Text
                                style={StyleSheet.flatten([
                                    styles.buttonText,
                                    value ? styles.buttonTextSelected : null,
                                ])}
                            >
                                Yes
                            </Text>
                        </Pressable>
                        <Pressable
                            style={StyleSheet.flatten([
                                styles.radioButton,
                                value ? null : styles.radioButtonSelected,
                                styles.buttonRight,
                            ])}
                            onPress={() => {
                                onChange(false);
                            }}
                        >
                            <Text
                                style={StyleSheet.flatten([
                                    styles.buttonText,
                                    value ? null : styles.buttonTextSelected,
                                ])}
                            >
                                No
                            </Text>
                        </Pressable>
                    </View>
                    <Text style={styles.errorMsg}>
                        {error && error.message}
                    </Text>
                </View>
            )}
        />
    );
}
