import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Notifications from 'expo-notifications';
import * as Device from 'expo-device';
import { Linking, Platform } from 'react-native';
import { api } from '../api';
import Colors from '../constants/Colors';
import { Subscription } from 'expo-notifications';

export interface NotifsStatus {
    status: 'OK' | 'ERROR';
}

Notifications.setNotificationHandler({
    handleNotification: async () => ({
        shouldShowAlert: true,
        shouldPlaySound: true,
        shouldSetBadge: true,
    }),
});

async function registerForPushNotificationsAsync(): Promise<string> {
    let token = '';

    //Don't register for push notifications if running on web or simulator
    if (Platform.OS === 'web') return token;
    if (!Device.isDevice) return token;

    const { status: existingStatus } =
        await Notifications.getPermissionsAsync();
    let finalStatus = existingStatus;
    if (existingStatus !== 'granted') {
        const { status } = await Notifications.requestPermissionsAsync();
        finalStatus = status;
    }
    if (finalStatus !== 'granted') {
        alert('Failed to get push token for push notification!');
        return token;
    }

    token = (await Notifications.getExpoPushTokenAsync()).data;

    if (Platform.OS === 'android') {
        Notifications.setNotificationChannelAsync('default', {
            name: 'default',
            importance: Notifications.AndroidImportance.MAX,
            vibrationPattern: [0, 250, 250, 250],
            lightColor: Colors.theme.orange,
        });
    }

    return token;
}

export default function useNotifs(): NotifsStatus {
    const [expoPushToken, setExpoPushToken] = useState('');
    const [error, setError] = useState<string>('');
    const responseListener = useRef<Subscription>();

    //Cache push token to reduce update calls to DB
    const syncWithDB = useCallback(async () => {
        if (!expoPushToken) return;
        await api.updatePushToken(expoPushToken).catch((err) => {
            console.error(err);
        });
    }, [expoPushToken, api.updatePushToken]);

    useEffect(() => {
        (async () => {
            await registerForPushNotificationsAsync().then((token) => {
                setExpoPushToken(token);
            });
        })();

        responseListener.current =
            Notifications.addNotificationResponseReceivedListener(
                (response) => {
                    if (response.notification.request.content.data) {
                        const notifData =
                            response.notification.request.content.data;
                        if (notifData.relevantUrl) {
                            const decodedUrl = decodeURI(notifData.relevantUrl);
                            Linking.openURL(decodedUrl);
                        }
                    }
                },
            );

        return () => {
            if (responseListener.current) {
                Notifications.removeNotificationSubscription(
                    responseListener.current,
                );
            }
        };
    }, []);

    return { status: error ? 'ERROR' : 'OK' };
}
