import { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
    TextInput,
    ScrollView,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import { AdminDashboardStackProps } from '../../navigation/types';
import { useNavigation, useFocusEffect } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import ThemedButton from '../../components/ThemedButton';
import { Admin } from '../../types';
import { useQuery, useQueryClient } from 'react-query';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminManageRecruitersScreen() {
    const { control, handleSubmit } = useForm<{ searchQuery: string }>();
    const queryClient = useQueryClient();
    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [admins, setAdmins] = useState<Admin[]>([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [filteredSearchResults, setFilteredSearchResults] = useState<Admin[]>(
        [],
    );
    type AdminListItem = {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    };

    useQuery(['users', user?.id], () => api.getAllAdmins(), {
        onSuccess: (data) => {
            setAdmins(data);
            setFilteredSearchResults(data);
        },
        onError: (err) => {
            setErrMsg('Error fetching admins.');
        },
    });

    useEffect(() => {
        if (searchQuery === '') {
            setFilteredSearchResults(admins);
            return;
        }

        setFilteredSearchResults(
            admins.filter((admin: AdminListItem) => {
                admin.firstName
                    .toLowerCase()
                    .indexOf(searchQuery.toLowerCase()) > 0;
            }),
        );
    }, [searchQuery]);

    const Item = ({
        id,
        firstName,
        lastName,
        email,
    }: {
        id: string;
        firstName: string;
        lastName: string;
        email: string;
    }) => (
        <TouchableOpacity
            onPress={() => navigation.navigate('EditUser', { id: id })}
        >
            <View style={styles.applicantListItem}>
                <Text style={styles.applicantListItemTitle}>
                    {firstName} {lastName}
                </Text>
                <Text style={styles.emailText}>{email}</Text>
            </View>
        </TouchableOpacity>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Recruiters</Text>
                    <ThemedButton
                        title="Add New"
                        color="black"
                        onPress={() => navigation.navigate('EditUser', {})}
                    />
                </View>
                <CtlTextInput
                    name="searchQuery"
                    control={control}
                    rules={required}
                    placeholder="Search"
                />
                {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                <View style={styles.applicantListContainer}>
                    {admins.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredSearchResults}
                            renderItem={({ item }) => (
                                <Item
                                    id={item.id}
                                    firstName={item.firstName}
                                    lastName={item.lastName}
                                    email={item.email}
                                />
                            )}
                            keyExtractor={(item: AdminListItem) => item.id}
                        />
                    )}
                    {admins.length <= 0 && <Text>No admins found.</Text>}
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    searchContainer: {
        marginVertical: 20,
        flexDirection: 'row',
        width: '100%',
    },
    applicantListContainer: {
        width: '100%',
    },
    applicantListItem: {
        height: 75,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingLeft: 20,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        backgroundColor: Colors.theme.white,
        ...Theme.listItemShadow,
    },
    applicantListItemTitle: {
        fontSize: 18,
        fontWeight: '500',
    },
    emailText: {
        fontSize: 14,
        color: Colors.theme.lightGray,
        marginTop: 5,
    },
});
