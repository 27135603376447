import {
    FieldValues,
    FieldPath,
    FieldError,
    UseFormSetValue,
    UseFormGetValues,
} from 'react-hook-form';
import { ControlledInputProps } from '../../types/ControlledInput';
import { useState } from 'react';
import {
    en,
    registerTranslation,
    DatePickerModal,
} from 'react-native-paper-dates';
import { View, Pressable, Text, ViewStyle } from 'react-native';
import { format as formatDate, parseISO, isDate } from 'date-fns';
import Theme from '../../constants/Theme';
registerTranslation('en', en);
export interface DatePickerInputProps<K> {
    getValueFn: UseFormGetValues<K>;
    setValueFn: UseFormSetValue<K>;
    label?: string;
    error?: FieldError | undefined;
    startDate?: Date | undefined;
    endDate?: Date | undefined;
    style?: ViewStyle | undefined;
}

export default function CtlDatePickerInput<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    setValueFn,
    getValueFn,
    ...rest
}: ControlledInputProps<TFieldValues, TName> &
    DatePickerInputProps<TFieldValues>) {
    const { label, startDate, endDate, style } = rest;
    const [open, setOpen] = useState(false);

    const onDismissSingle = () => {
        setOpen(false);
    };

    //Because we have to anticipate different date formats from backend and modal
    // (ISO, JS Date, etc.) we have to do some parsing and validation here.
    // We take the useForm getter and setter so that we can update the form value manuall
    const handleUpdateValue = (newVal: Date) => {
        const parsedDate = parseISO(newVal.toISOString());
        setValueFn(name, parsedDate as TFieldValues[TName]);
    };

    const getInputText = () => {
        if (getValueFn(name)) {
            let parsedDate: Date;
            if (isDate(getValueFn(name))) {
                parsedDate = getValueFn(name);
            } else {
                parsedDate = parseISO(getValueFn(name));
            }
            return formatDate(parsedDate, 'MM/dd/yyyy');
        }
        return 'Select Date';
    };

    const getSafeDate = () => {
        if (getValueFn(name)) {
            const parsedDate = parseISO(getValueFn(name));
            return parsedDate;
        }
        return new Date();
    };

    return (
        <View style={[Theme.inputContainer, style]}>
            <Text style={Theme.inputLabel}>{label}</Text>
            <Pressable
                onPress={(e) => {
                    e.preventDefault();
                    setOpen(true);
                }}
                style={Theme.input}
            >
                <Text>{getInputText()}</Text>
            </Pressable>
            {/* <Text style={Theme.errMsg}>{fieldError?.message}</Text> */}
            <DatePickerModal
                locale="en"
                mode="single"
                visible={open}
                onDismiss={onDismissSingle}
                date={getSafeDate()}
                onConfirm={(newVal) => {
                    if (!newVal.date) return;
                    handleUpdateValue(newVal.date);
                    setOpen(false);
                }}
                validRange={{
                    startDate: startDate,
                    endDate: endDate,
                }}
            />
        </View>
    );
}
