import { View, StyleSheet, Text, Pressable, ViewStyle } from 'react-native';
import Colors from '../constants/Colors';
import Checkbox, { CheckboxProps } from 'expo-checkbox';
import Theme from '../constants/Theme';

/**
 * A simple themed checkbox component with label. Intended for read-only use.
 */
export const ThemedCheckbox = (props: {
    value?: boolean;
    label?: string;
    style?: ViewStyle;
	onValueChange?: (value: boolean) => void;
}) => {
    const styles = StyleSheet.create({
        container: {
            flexDirection: 'row',
			marginVertical: 10
        },
        baseLabel: {
            marginLeft: 8,
            fontSize: 14,
            fontWeight: '500',
        },
        labelUnchecked: {
            color: Colors.theme.lightGray,
        },
        labelChecked: {
            color: Colors.theme.black,
        },
    });

    return (
        <View style={[styles.container, props.style ?? {}]}>
            <Checkbox
                value={props.value ?? false}
                color={props.value ? Colors.theme.lightOrange : undefined}
				onValueChange={(value) => props.onValueChange?.(value)}
            />
            <Text
                style={[
                    props.value ? styles.labelChecked : styles.labelUnchecked,
                    styles.baseLabel,
                ]}
            >
                {props.label ?? ''}
            </Text>
        </View>
    );
};
export default ThemedCheckbox;
