import {
    useController,
    Controller,
    FieldValues,
    FieldPath,
} from 'react-hook-form';
import { ControlledInputProps } from '../../types/ControlledInput';
import { View, StyleSheet, Text, Pressable } from 'react-native';
import Colors from '../../constants/Colors';
import Checkbox, { CheckboxProps } from 'expo-checkbox';
import Theme from '../../constants/Theme';

export interface CustomCheckboxProps extends CheckboxProps {
    label?: string;
    inputRequired?: boolean;
}

/**
 * A controlled checkbox component that uses react-hook-form on top of
 * a custom checkbox component also defined here.
 */
export default function CtlCheckbox<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & CustomCheckboxProps) {
    const {
        field,
        fieldState: { error },
    } = useController<TFieldValues, TName>({
        name,
        control,
        rules,
    });
    const { label, inputRequired } = rest;

    const styles = StyleSheet.create({
        container: {
            flexDirection: 'column',
            marginVertical: 10,
            textAlignVertical: 'center',
        },
        inputContainer: {
            flexDirection: 'row',
        },
        baseLabel: {
            marginLeft: 8,
            fontSize: 14,
            fontWeight: '500',
        },
        labelUnchecked: {
            color: Colors.theme.lightGray,
        },
        labelChecked: {
            color: Colors.theme.black,
        },
        requiredMark: {
            color: Colors.theme.orange,
        },
        errorMsg: {
            color: Colors.theme.error,
            textAlign: 'left',
        },
        errorContainer: {},
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <View>
                    <Pressable
                        onPress={() => onChange(!value)}
                        style={styles.inputContainer}
                    >
                        <Checkbox
                            value={value ? true : false}
                            onValueChange={(value) => onChange(value)}
                            color={value ? Colors.theme.lightOrange : undefined}
                        />
                        <Text
                            style={[
                                value
                                    ? styles.labelChecked
                                    : styles.labelUnchecked,
                                styles.baseLabel,
                            ]}
                        >
                            {label}
                            {inputRequired && (
                                <Text style={styles.requiredMark}>*</Text>
                            )}
                        </Text>
                    </Pressable>
                    <View style={styles.errorContainer}>
                        <Text style={styles.errorMsg}>
                            {error && error.message}
                        </Text>
                    </View>
                </View>
            )}
        />
    );
}
