import {
    StyleSheet,
    Image,
    Dimensions,
    View,
    Text,
	Pressable,
} from 'react-native';
import Theme from '../constants/Theme';
import ThemedButton from '../components/ThemedButton';
import Colors from '../constants/Colors';
import useVersion from '../hooks/useVersion';
import { useNavigation } from '@react-navigation/native';
import { PublicStackNavigationProps } from '../navigation/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import JobGigStyledText from '../components/JobGigStyledText';
import { StatusBar } from 'expo-status-bar';
import Onboarding from '../components/Onboarding';
import { useState } from 'react';
import { authService } from '../util/authService';
import {
    widthPercentageToDP as wp,
    heightPercentageToDP as hp,
} from 'react-native-responsive-screen';
import ThemedScrollView from '../components/ThemedScrollView';
import Logo from '../assets/Logo';
export default function WelcomeScreen() {
    const version = useVersion();
    const safeArea = useSafeAreaInsets();
    const navigation = useNavigation<PublicStackNavigationProps>();
    const [firstVisit, setFirstVisit] = useState(true);

    // If the user has already visited the app, we don't want to show the onboarding tutorial.
    init();
    async function init() {
        let isFirstVisit = await authService.getIsFirstVisit();
        setFirstVisit(isFirstVisit);
    }

    async function onFinishOnboarding() {
        setFirstVisit(false);
        await authService.setFirstVisitDone();
    }

    return (
        <ThemedScrollView>
            <StatusBar style="inverted" />
            <View
                style={[
                    Theme.pageContent,
                    { width: wp('99%'), height: hp('95%'), marginTop: 35 },
                ]}
            >
                {firstVisit ? (
                    <Onboarding onFinish={onFinishOnboarding} />
                ) : (
                    <>
                        <View style={styles.welcomeImageContainer}>
                            <Image
                                style={styles.welcomeImage}
                                source={require('../assets/handshake.png')}
                            />
                        </View>
                        <View style={styles.headerContainer}>
							<Logo width={300} height={150} />
                            {/* <Text style={styles.subHeader}>
                                <Text style={styles.subHeaderBlack}>
                                    Let's get to{' '}
                                </Text>
                                <Text style={styles.subHeaderOrange}>work</Text>
                                <Text style={styles.subHeaderBlack}>.</Text>
                            </Text> */}
                        </View>
                        <ThemedButton
                            title="Create an Account"
                            variant="large"
                            color="black"
                            onPress={() => navigation.navigate('CreateAccount')}
                        />
                        <View style={styles.existingAccountContainer}>
                            <Text
                                style={StyleSheet.flatten([
                                    styles.existingAccountText,
                                    styles.existingAccountTextBold,
                                ])}
                            >
                                Already have an account?
                            </Text>
                            <Text
                                style={StyleSheet.flatten([
                                    styles.existingAccountText,
                                    styles.signInLink,
                                ])}
                                onPress={() => navigation.navigate('Login')}
                            >
                                Sign In
                            </Text>
                            <Pressable style={styles.passportMethodsContainer} onPress={() => navigation.navigate('Login')}>
                                <Image
                                    source={require('../assets/google-icon.png')}
                                    style={styles.passportMethodLogo}
                                />
                                <Image
                                    source={require('../assets/apple-icon.png')}
                                    style={styles.passportMethodLogo}
                                />
                            </Pressable>
                        </View>
                        <View style={styles.footerContainer}>
                            <Text style={styles.version}>
                                JobGig Application Version {version}
                            </Text>
                        </View>
                    </>
                )}
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    welcomeImageContainer: {
        height: hp('35%'),
        width: wp('95%'),
    },
    welcomeImage: {
        height: '100%',
        width: '100%',
        borderRadius: 20,
    },
    headerContainer: {
        marginTop: 15,
        marginBottom: 35,
        alignItems: 'center',
    },
    subHeader: {
        fontSize: 15,
        fontWeight: '500',
    },
    subHeaderBlack: {
        color: Colors.theme.black,
    },
    subHeaderOrange: {
        color: Colors.theme.orange,
    },
    existingAccountContainer: {
        marginTop: 50,
        marginBottom: 40,
    },
    existingAccountText: {
        fontSize: 16,
    },
    existingAccountTextBold: {
        fontWeight: 'bold',
    },
    signInLink: {
        textDecorationLine: 'underline',
        textAlign: 'center',
    },
    passportMethodsContainer: {
        flexDirection: 'row',
        marginTop: 30,
        marginBottom: 5,
        justifyContent: 'space-evenly',
    },
    passportMethodLogo: {
        height: 30,
        width: 30,
        resizeMode: 'contain',
    },
    footerContainer: {
        alignItems: 'center',
    },
    version: {
        color: Colors.theme.lightGray,
        fontSize: 12,
    },
});
