import { useState, useEffect } from 'react';
import { StyleSheet, View, Text, Linking, ScrollView } from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import Colors from '../../../constants/Colors';
import { UpdateApplicantDto } from '../../../types';
import { useForm } from 'react-hook-form';
import { required } from '../../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import CtlCheckbox from '../../../components/ControlledInputs/CtlCheckbox';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AccuracyConfirmationScreen() {
    type AccuracyConfirmationFormArgs = Pick<
        UpdateApplicantDto,
        'id' | 'accuracyConfirmation' | 'backgroundConsent'
    >;

    const {
        control,
        setValue,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<AccuracyConfirmationFormArgs>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();

    const editMutation = useMutation(
        (d: UpdateApplicantDto) => api.updateApplicant(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries('profileCompletion');
            },
        },
    );

    useEffect(() => {
        async function fetchUser() {
            if (!user) return;
            await api
                .getApplicant(user.id)
                .then((res) => {
                    reset(res);
                })
                .catch((err) => {
                    setErrMsg(err);
                });
        }
        fetchUser();
    }, []);

    const submitForm = async (d: UpdateApplicantDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            await editMutation.mutateAsync(d);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        Confirmation of Accuracy & Background Check Consent
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <Text style={styles.eligibilityNotice}>
                        By checking the box and continuing, I confirm that the
                        information provided on my profile is accurate and
                        truthful. I also consent to this information being used
                        to perform a background check. I understand that fields
                        left blank must be completed before I will be matched
                        with any jobs.
                    </Text>
                    <View style={styles.workAuthContainer}>
                        <CtlCheckbox
                            name="accuracyConfirmation"
                            control={control}
                            rules={required}
                            label="I confirm that I have read and agree with the statement above."
                        />

                        <CtlCheckbox
                            name="backgroundConsent"
                            control={control}
                            rules={required}
                            label="I agree and consent to JobGig running a background check on me."
                        />
                    </View>
                </View>

                <Text style={styles.disclaimer}>
                    You can visit our website to read the full Privacy Policy
                    and disclaimer:{' '}
                    <Text
                        style={{ textDecorationLine: 'underline' }}
                        onPress={() =>
                            Linking.openURL('http://jobgig.com/privacy-policy')
                        }
                    >
                        http://jobgig.com/privacy-policy
                    </Text>
                </Text>
                <>{errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}</>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Confirm Accuracy"
                    variant="fluid"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    formLabel: {
        fontSize: 32,
        marginBottom: 20,
        fontWeight: '500',
    },
    formInput: {
        marginBottom: 20,
    },
    eligibilityNotice: {
        fontSize: 14,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 20,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.lightGray,
    },
    workAuthContainer: {
        marginVertical: 20,
    },
    disclaimer: {
        fontSize: 12,
    },
});
