import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { CustomSVGIconProps } from '../types';
const PencilIcon = ({
    width = 10,
    height = 10,
    onPressCB,
    ...rest
}: CustomSVGIconProps): JSX.Element => {
    return (
        <Svg
            width={width}
            height={height}
            viewBox="0 0 15 15"
            fill="none"
            onPress={onPressCB}
            {...rest}
        >
            <Path
                d="M10.625 1.87519C10.7892 1.71104 10.984 1.58082 11.1985 1.49199C11.413 1.40315 11.6429 1.35742 11.875 1.35742C12.1071 1.35742 12.337 1.40315 12.5515 1.49199C12.766 1.58082 12.9608 1.71104 13.125 1.87519C13.2892 2.03934 13.4194 2.23422 13.5082 2.44869C13.597 2.66317 13.6428 2.89304 13.6428 3.12519C13.6428 3.35734 13.597 3.58721 13.5082 3.80168C13.4194 4.01616 13.2892 4.21104 13.125 4.37519L4.6875 12.8127L1.25 13.7502L2.1875 10.3127L10.625 1.87519Z"
                stroke="black"
                strokeWidth="1.25"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default PencilIcon;
