import {
    useController,
    FieldValues,
    FieldPath,
    Controller,
} from 'react-hook-form';

import { ControlledInputProps } from '../../types/ControlledInput';
import Colors from '../../constants/Colors';
import {
    View,
    StyleSheet,
    Text,
    Pressable,
    ViewStyle,
    Switch,
    SwitchProps,
    StyleProp,
} from 'react-native';
import Theme from '../../constants/Theme';

export interface CustomSwitchProps extends SwitchProps {
    label?: string;
    style?: StyleProp<ViewStyle>;
}

/**
 * A controlled switch component that uses react-hook-form on top of
 * a custom switch component also defined here.
 */
export default function CtlSwitch<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    label,
    style,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & CustomSwitchProps) {
    const {
        field,
        fieldState: { error },
    } = useController<TFieldValues, TName>({
        name,
        control,
        rules,
    });

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, onBlur, value } }) => (
                <View
                    style={StyleSheet.flatten([
                        Theme.pageContent,
                        style,
                        { flexDirection: 'row', display: 'flex' },
                    ])}
                >
                    <Switch
                        value={value}
                        onValueChange={onChange}
                        trackColor={{
                            false: Colors.theme.lightGray,
                            true: Colors.theme.orange,
                        }}
                    />
                    <Text style={{ marginLeft: 10, fontWeight: 'bold' }}>
                        {label}
                    </Text>
                </View>
            )}
        />
    );
}
