/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import { LinkingOptions } from '@react-navigation/native';
import * as Linking from 'expo-linking';

import { ProtectedStackParamList, PublicStackParamList } from './types';

const linking: LinkingOptions<ProtectedStackParamList | PublicStackParamList> =
    {
        prefixes: [Linking.createURL('/'), 'jobgig://'],
        // TODO: change to jobgig domain when registered
        // https://reactnavigation.org/docs/deep-linking/
        config: {
            screens: {
                ApplicantTabs: {
                    path: 'applicant',
                    screens: {
                        ApplicantTabsDashboard: 'dashboard',
						JobMatches: 'job-matches',
                    },
                },
                AdminTabs: {
                    path: 'admin',
                    screens: {
                        JobPostings: 'job-postings',
                    },
                },
                Login: 'login',
                CreateAccount: 'create-account',
                Welcome: 'welcome',
				PasswordReset: 'reset-password',
            },
        },
    };
export default linking;
