import { useState, useEffect } from 'react';
import { StyleSheet, View, Text, TouchableOpacity } from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import Colors from '../../../constants/Colors';
import { useForm } from 'react-hook-form';
import { required } from '../../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import CtlDatePickerInput from '../../../components/ControlledInputs/CtlDatePickerInput';
import { Ionicons } from '@expo/vector-icons';
import { Applicant, County } from '../../../types';
import CtlCheckbox from '../../../components/ControlledInputs/CtlCheckbox';
import CtlSlider from '../../../components/ControlledInputs/CtlSlider';
import { UpdateApplicantDto } from '../../../types/Applicant';
import CtlSelectInput from '../../../components/ControlledInputs/CtlSelectInput';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function JobPreferencesScreen() {
    type JobPreferencesFormArgs = Pick<Applicant, 'id' | 'jobPreference'>;

    const {
        control,
        setValue,
        getValues,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<JobPreferencesFormArgs>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    useQuery('jobPreferences', fetchJobPreferences);
    const { user } = useUser();
    const [counties, setCounties] = useState([] as County[]);
    const [selectedCounties, setSelectedCounties] = useState(
        [] as Record<string, any>[],
    );

    const handleAddSelectedCounty = (id: string) => {
        if (!id) return;
        const existingSelection = selectedCounties.find((c) => c.value === id);
        if (existingSelection) return; //Don't add duplicate counties

        const countyToAdd = counties.find((c) => c.id === id);
        if (countyToAdd) {
            setSelectedCounties([
                ...selectedCounties,
                { label: countyToAdd.name, value: countyToAdd.id },
            ]);

            const tempArray = getValues('jobPreference.counties');
            tempArray.push(countyToAdd);
            setValue('jobPreference.counties', tempArray);
        }
    };

    const handleRemoveSelectedCounty = (id: string) => {
        if (!id) return;
        const tempArray = selectedCounties.filter((c) => c.value !== id);
        setSelectedCounties(tempArray);
    };

    const editMutation = useMutation(
        (d: UpdateApplicantDto) => api.updateApplicant(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries('jobPreferences');
                queryClient.invalidateQueries('profileCompletion');
            },
        },
    );

    useEffect(() => {
        async function initialLoad() {
            await fetchCounties();
        }
        initialLoad();
    }, []);

    async function fetchJobPreferences() {
        if (!user) return;
        const applicant = await api.getApplicant(user.id);
        let selectedCounties;
        if (applicant.jobPreference?.counties) {
            selectedCounties = applicant.jobPreference.counties.map(
                (c: County) => {
                    return {
                        label: c.name,
                        value: c.id,
                    };
                },
            );
            reset(applicant);
            setSelectedCounties(selectedCounties);
        }
    }

    async function fetchCounties() {
        if (!user) return;
        await api
            .getAllCounties()
            .then((res) => {
                const tempCountiesArray = res;
                tempCountiesArray.unshift({ id: '', name: 'Select a county' }); //Add a blank option to the beginning of the array
                setCounties(tempCountiesArray);
            })
            .catch((err) => {
                setErrMsg(err);
            });
    }

    const submitForm = async (d: UpdateApplicantDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            if (user) await editMutation.mutateAsync(d);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Job Preferences</Text>
                </View>
                <View style={Theme.formContainer}>
                    <CtlDatePickerInput
                        name="jobPreference.availableDate"
                        setValueFn={setValue}
                        getValueFn={getValues}
                        rules={required}
                        label="Available Start Date"
                        startDate={new Date()}
                    />

                    <Text style={styles.eligibilityNotice}>
                        What counties are you seeking work in?
                    </Text>
                    <View style={styles.countyPillContainer}>
                        {selectedCounties.length > 0 &&
                            selectedCounties.map((item) => {
                                return (
                                    <TouchableOpacity
                                        key={item.value}
                                        style={styles.countyPill}
                                        onPress={() =>
                                            handleRemoveSelectedCounty(
                                                item.value,
                                            )
                                        }
                                    >
                                        <Text style={styles.countyPillText}>
                                            {item.label}
                                        </Text>
                                        <Ionicons
                                            name="close"
                                            size={10}
                                            color="white"
                                            style={styles.countyPillIcon}
                                        ></Ionicons>
                                    </TouchableOpacity>
                                );
                            })}
                    </View>
                    <CtlSelectInput
                        name="jobPreference.counties"
                        control={control}
                        items={
                            counties.map((c: County) => {
                                return {
                                    label: c.name,
                                    value: c.id,
                                };
                            }) as Record<string, any>[]
                        }
                        // TODO: handleAddCounty on change or do watch effect
                    />
                    <Text style={styles.eligibilityNotice}>
                        What job types are you interested in?
                    </Text>
                    <CtlCheckbox
                        name="jobPreference.partTime"
                        control={control}
                        label="Part-Time"
                    />
                    <CtlCheckbox
                        name="jobPreference.fullTime"
                        control={control}
                        label="Full-Time"
                    />
                    <CtlCheckbox
                        name="jobPreference.seasonal"
                        control={control}
                        label="Seasonal"
                    />
                    <CtlSlider
                        name="jobPreference.minimumRate"
                        control={control}
                        label="Minimum Hourly Rate"
                        valuePrefix="$"
                        minimumValue={0}
                        maximumValue={40}
                        step={1}
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Save Job Preferences"
                    variant="fluid"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        backgroundColor: Colors.theme.backgroundColor,
        textAlign: 'left',
        alignItems: 'flex-start',
        width: '100%',
    },
    headerText: {
        fontSize: 32,
        fontWeight: '700',
    },
    eligibilityNotice: {
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 14,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.lightGray,
    },
    workAuthContainer: {
        marginVertical: 20,
    },
    sliderContainer: {
        marginTop: 20,
        justifyContent: 'center',
        alignItems: 'center',
        textAlignVertical: 'middle',
    },
    slider: {
        width: '100%',
        height: 40,
    },
    countyPillContainer: {
        flexDirection: 'row',
        overflow: 'visible',
        flexWrap: 'wrap',
    },
    countyPill: {
        marginVertical: 5,
        backgroundColor: Colors.theme.black,
        height: 30,
        minWidth: 50,
        maxWidth: 175,
        paddingHorizontal: 5,
        borderRadius: 5,
        marginRight: 10,
        justifyContent: 'center',
        flexDirection: 'row',
        textAlignVertical: 'center',
        alignItems: 'center',
    },
    countyPillText: {
        fontSize: 12,
        color: Colors.theme.white,
    },
    countyPillIcon: {
        marginTop: 2,
        marginLeft: 2,
    },
});
