import React from 'react';
import Svg, { Path } from 'react-native-svg';
import { CustomSVGIconProps } from '../types';

const CloseIcon = (props: CustomSVGIconProps) => {
    return (
        <Svg
            width={props.width ? props.width : '28'}
            height={props.height ? props.height : '28'}
            viewBox="0 0 28 28"
            fill="none"
        >
            <Path
                d="M14 28C21.732 28 28 21.732 28 14C28 6.26801 21.732 0 14 0C6.26801 0 0 6.26801 0 14C0 21.732 6.26801 28 14 28Z"
                fill="black"
            />
            <Path
                d="M20 8L8 20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <Path
                d="M8 8L20 20"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </Svg>
    );
};

export default CloseIcon;
