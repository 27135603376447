import { View, Text, ModalProps, StyleSheet, Pressable } from 'react-native';
import CloseIcon from '../assets/CloseIcon';
import Colors from '../constants/Colors';
import Theme from '../constants/Theme';
import { useUser } from '../hooks';
import { Job } from '../types';
import { UserRole } from '../types/User';
import ThemedButton from './ThemedButton';
import { useNavigation } from '@react-navigation/native';
import { AdminDashboardStackProps } from '../navigation/types';
import ThemedModal from './ThemedModal';

export interface JobPostingModalProps extends ModalProps {
    onCloseFunc: () => void;
    job?: Job;
}

export default function JobPostingModal(props: JobPostingModalProps) {
    const { user } = useUser();
    const adminNavigation = useNavigation<AdminDashboardStackProps>();

    function handleGoToEditJob() {
        if (props.job) {
            adminNavigation.navigate('EditJob', { id: props.job.id });
            props.onCloseFunc();
        }
    }

    function handleGoToViewMatches() {
        if (props.job) {
            adminNavigation.navigate('ViewJobMatches', { id: props.job.id });
            props.onCloseFunc();
        }
    }

    return (
        <ThemedModal
            visible={props.visible}
            onRequestClose={() => {
                props.onCloseFunc();
            }}
        >
            {props.job && (
                <>
                    <View style={styles.modalHeader}>
                        <View style={styles.modalHeaderLeft}>
                            <Text style={styles.modalHeaderTitle}>
                                {props.job.title}
                            </Text>
                            <Text style={styles.modalHeaderSubtitle}>
                                {props.job.jobCategory?.name}
                            </Text>
                        </View>
                        <View style={styles.modalHeaderRight}>
                            <Pressable onPress={props.onCloseFunc}>
                                <CloseIcon />
                            </Pressable>
                        </View>
                    </View>
                    <View style={styles.jobMetaContainer}>
                        {user &&
                            [UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                                user.role,
                            ) && (
                                <View style={styles.jobMetaWrapper}>
                                    <Text style={styles.jobMetaLabel}>
                                        Client
                                    </Text>
                                    <Text style={styles.jobMetaValue}>
                                        {props.job.client?.name}
                                    </Text>
                                </View>
                            )}
                        <View style={styles.jobMetaWrapper}>
                            <Text style={styles.jobMetaLabel}>Location</Text>
                            <Text style={styles.jobMetaValue}>
                                {props.job.address?.city}
                            </Text>
                        </View>
                    </View>
                    <View style={styles.jobDescriptionContainer}>
                        <Text style={styles.jobMetaLabel}>Description</Text>
                        <Text style={styles.jobMetaValue}>
                            {props.job.description}
                        </Text>
                    </View>

                    {user &&
                        [UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                            user.role,
                        ) && (
                            <View style={Theme.modalActionsContainer}>
                                <ThemedButton
                                    title="Edit Job"
                                    color="black"
                                    style={Theme.modalActionButton}
                                    onPress={handleGoToEditJob}
                                />
                                <ThemedButton
                                    title="View Matches"
                                    color="black"
                                    style={Theme.modalActionButton}
                                    onPress={handleGoToViewMatches}
                                />
                            </View>
                        )}
                </>
            )}
        </ThemedModal>
    );
}

const styles = StyleSheet.create({
    centeredView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        marginTop: 22,
    },
    modalHeader: {
        width: '100%',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: 10,
    },
    modalHeaderLeft: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '90%',
        justifyContent: 'flex-start',
    },
    modalHeaderRight: {
        display: 'flex',
        flexDirection: 'column',
        flexBasis: '10%',
        justifyContent: 'flex-end',
    },
    modalHeaderTitle: {
        fontSize: 16,
        fontWeight: 'bold',
    },
    modalHeaderSubtitle: {
        fontSize: 14,
    },
    modalText: {
        textAlign: 'center',
    },
    outsideModal: {
        backgroundColor: 'rgba(1, 1, 1, 0.2)',
        flex: 1,
    },
    modalButtonContainer: {
        flexDirection: 'row',
    },
    jobMetaContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },
    jobMetaWrapper: {
        display: 'flex',
        flexDirection: 'column',
        marginRight: 20,
    },
    jobMetaLabel: {
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    jobMetaValue: {
        fontSize: 12,
        color: Colors.theme.black,
    },
    jobDescriptionContainer: {
        marginTop: 20,
    },
    jobDescription: {},
});
