import { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
    ScrollView,
    Pressable,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import { AdminDashboardStackProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import ThemedButton from '../../components/ThemedButton';
import { Address, Client } from '../../types';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import { filterSearchResults } from '../../util/helperFunctions';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminManageClientsScreen() {
    const {
        control,
        handleSubmit,
        watch: watchFormValue,
    } = useForm<{ searchQuery: string }>();
    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [clients, setClients] = useState<Client[]>([]);
    const [filteredClients, setFilteredClients] = useState<Client[]>([]);

    useQuery('clients', () => api.getClients(), {
        onSuccess: (data) => {
            setErrMsg('');
            setClients(data);
        },
        onError: () => {
            setErrMsg('Error fetching clients');
        },
    });

    useEffect(() => {
        setFilteredClients(
            filterSearchResults(watchFormValue('searchQuery'), clients, [
                'name',
            ]),
        );
    }, [watchFormValue('searchQuery'), clients]);

    const Item = ({
        id,
        name,
        address,
    }: {
        id: string;
        name: string;
        address: Address;
    }) => (
        <Pressable
            onPress={() => navigation.navigate('EditClient', { id })}
            style={({ pressed }) => [{ opacity: pressed ? 0.5 : 1 }]}
        >
            <View style={styles.applicantListItem}>
                <Text style={styles.applicantListItemTitle}>{name}</Text>
                <Text style={styles.emailText}>
                    {address.city}, {address.state}
                </Text>
            </View>
        </Pressable>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Clients</Text>
                    <ThemedButton
                        title="Add New"
                        color="black"
                        variant="small"
                        onPress={() => navigation.navigate('EditClient', {})}
                    />
                </View>
                <View style={styles.searchContainer}>
                    <CtlTextInput
                        name="searchQuery"
                        control={control}
                        rules={required}
                        placeholder="Search"
                    />
                </View>
                <View style={styles.applicantListContainer}>
                    {filteredClients.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredClients}
                            renderItem={({ item }) => (
                                <Item
                                    id={item.id}
                                    name={item.name}
                                    address={item.address}
                                />
                            )}
                            keyExtractor={(item: Client) => item.id}
                        />
                    )}
                    {filteredClients.length <= 0 && (
                        <Text>No clients found.</Text>
                    )}
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    searchContainer: {
        marginVertical: 20,
        flexDirection: 'column',
        alignSelf: 'stretch',
        alignItems: 'center',
    },
    applicantListContainer: {
        width: '100%',
    },
    applicantListItem: {
        height: 75,
        backgroundColor: Colors.theme.white,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingLeft: 20,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        ...Theme.listItemShadow,
    },
    applicantListItemTitle: {
        fontSize: 18,
        fontWeight: '500',
    },
    emailText: {
        fontSize: 14,
        color: Colors.theme.lightGray,
        marginTop: 5,
    },
    fillOutProfileButton: {
        marginTop: 75,
    },
});
