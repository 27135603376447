import AccountProgressComplete from '../assets/AccountProgressComplete';
import AccountProgressConfirmed from '../assets/AccountProgressConfirmed';
import AccountProgressMade from '../assets/AccountProgressMade';
import AccountProgressReady from '../assets/AccountProgressReady';

const AccountProgressBar = ({ step }: { step: number }): JSX.Element | null => {
    switch (step) {
        case 1:
            return <AccountProgressMade />;
        case 2:
            return <AccountProgressComplete />;
        case 3:
            return <AccountProgressConfirmed />;
        case 4:
            return null
        default:
            return <AccountProgressMade />;
    }
};

export default AccountProgressBar;
