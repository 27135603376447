import { useState } from 'react';
import { View, Text, ScrollView } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant } from '../../../types';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
} from '../../../navigation/types';
import { useQuery } from 'react-query';
import { capitalizeString } from '../../../util/helperFunctions';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewContactScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantContact'>) {
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    useQuery(
        ['applicant', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
			onError: (err) => {
				setErrMsg('Error loading applicant data.');
			}
        },
    );
    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        Profile & Contact Details
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>First Name</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.firstName}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Middle Name</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.middleName}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Last Name</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.lastName}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Phone Number</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.phone}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Address Line One</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.address?.addressOne}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Address Line Two</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.address?.addressTwo}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>City</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.address?.city}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>State</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.address?.state}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Zip Code</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.address?.zipCode}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            Preferred Method of Communication
                        </Text>
                        <Text style={Theme.dataValue}>
                            {capitalizeString(
                                applicantToView?.commPreference ?? '',
                            )}
                        </Text>
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}
