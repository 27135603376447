import React from 'react';
import { Pressable, StyleSheet, Text } from 'react-native';
import { View } from './Themed';
import Colors from '../constants/Colors';
import { useNavigation } from '@react-navigation/native';
import { Ionicons } from '@expo/vector-icons';
import {
    AdminTabsNavigationProps,
    ApplicantTabsNavigationProps,
    ProtectedStackNavigationProps,
    PublicStackNavigationProps,
} from '../navigation/types';
import { useSafeAreaInsets } from 'react-native-safe-area-context';
import { UserRole } from '../types/User';
import { useAuth } from '../hooks';
import Logo from '../assets/Logo';

export default function BaseHeader({
    style = 'dark',
}: {
    style?: 'light' | 'dark';
}) {
    const insets = useSafeAreaInsets();
    const { user } = useAuth();
    const adminNavigation = useNavigation<AdminTabsNavigationProps>();
    const applicantNavigation = useNavigation<ApplicantTabsNavigationProps>();
    const protectedNavigation = useNavigation<ProtectedStackNavigationProps>();
    const publicNavigation = useNavigation<PublicStackNavigationProps>();
    const openNotifications = () => {
        protectedNavigation.navigate('Notifications');
    };

    function handleGoToDashboard() {
        if (
            user &&
            [UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                user?.role as UserRole,
            )
        ) {
            return adminNavigation.navigate('AdminDashboardTab');
        } else if (user && user?.role === UserRole.APPLICANT) {
            return applicantNavigation.navigate('ApplicantTabsDashboard');
        }
        return;
    }

    function handleGoBack() {
        if (user && protectedNavigation.canGoBack()) {
            protectedNavigation.goBack();
        } else if (publicNavigation.canGoBack()) {
            publicNavigation.goBack();
        }
    }

    return (
        <View
            style={{
                paddingTop: insets.top,
                paddingLeft: insets.left,
                paddingRight: insets.right,
                paddingBottom: 10,
                backgroundColor: `${
                    style === 'light' ? Colors.theme.white : Colors.theme.black
                }`,
            }}
        >
            {protectedNavigation.canGoBack() && (
                <View
                    style={[
                        styles.iconContainer,
                        {
                            left: insets.left + 15,
                            top: insets.top + 5,
                            backgroundColor: `${
                                style === 'light'
                                    ? Colors.theme.white
                                    : Colors.theme.black
                            }`,
                            zIndex: 2, // Ensures icon overlays center text container
                        },
                    ]}
                >
                    <Pressable onPress={handleGoBack}>
                        <Ionicons
                            name="chevron-back"
                            size={24}
                            color={
                                style === 'light'
                                    ? Colors.theme.black
                                    : Colors.theme.white
                            }
                        />
                    </Pressable>
                </View>
            )}

            {style === 'dark' && user && (
                <View style={styles.headerLogoContainer}>
                    <Pressable onPress={handleGoToDashboard}>
                        <Logo width={140} height={55} />
                    </Pressable>
                </View>
            )}

            <View
                style={[
                    styles.iconContainer,
                    {
                        right: insets.right + 15,
                        top: insets.top + 5,
                        backgroundColor: `${
                            style === 'light'
                                ? Colors.theme.white
                                : Colors.theme.black
                        }`,
                    },
                ]}
            >
                {user && (
                    <Ionicons
                        name="notifications-outline"
                        size={24}
                        color="white"
                        onPress={openNotifications}
                    />
                )}
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.theme.black,
        textAlign: 'center',
        justifyContent: 'center',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 'auto',
        paddingRight: 'auto',
    },
    headerLogoContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
		backgroundColor: Colors.theme.black,
    },
    iconContainer: {
        position: 'absolute',
    },
});
