import { useState } from 'react';
import { View, Text, ScrollView, StyleSheet, FlatList } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant, County } from '../../../types';
import { api } from '../../../api';
import { AdminDashboardStackScreenProps } from '../../../navigation/types';
import { useQuery } from 'react-query';
import ThemedCheckbox from '../../../components/ThemedCheckbox';
import { format, parseISO } from 'date-fns';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewJobPreferencesScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantJobPreferences'>) {
    const [errMsg, setErrMsg] = useState('');
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    useQuery(
        ['applicant', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );
    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Job Preferences</Text>
                </View>
                <Text style={Theme.subHeader}>
                    {applicantToView?.firstName} {applicantToView?.lastName}
                </Text>
                <View style={Theme.formContainer}>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            Available Start Date
                        </Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.jobPreference.availableDate &&
                                format(
                                    parseISO(applicantToView.dob.toString()),
                                    'MM/dd/yyyy',
                                )}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            What counties are you seeking work in?
                        </Text>
                        <FlatList
                            scrollEnabled={false}
                            data={applicantToView?.jobPreference.counties}
                            renderItem={({ item }) => (
                                <Text style={Theme.dataValue}>{item.name}</Text>
                            )}
                            keyExtractor={(item: County, idx) => idx.toString()}
                        />
                    </View>
                    <View style={[Theme.dataContainer, { marginTop: 40 }]}>
                        <Text style={Theme.dataLabel}>
                            What Job types are you seeking?
                        </Text>
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Part-Time"
                            value={applicantToView?.jobPreference.partTime}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Full-Time"
                            value={applicantToView?.jobPreference.fullTime}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Seasonal"
                            value={applicantToView?.jobPreference.seasonal}
                        />
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            Preferred Minimum Hourly Rate
                        </Text>
                        <Text style={Theme.dataValue}>
                            ${applicantToView?.jobPreference.minimumRate}
                        </Text>
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    checkbox: {
        marginTop: 14,
    },
});
