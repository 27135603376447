import { axiosInstance } from './axios';
import {
    User,
    Applicant,
    Admin,
    LoginArgs,
    CreateAccountArgs,
    UpdateApplicantDto,
    UpdateAdminUserDto,
    JobHistory,
    CreateJobHistoryDto,
    County,
    JobCategory,
    CreateJobCategoryDto,
    UpdateJobCategoryDto,
    Client,
    CreateClientDto,
    UpdateClientDto,
    Job,
    CreateJobDto,
    UpdateJobDto,
    CallLog,
    CreateCallLogDto,
    UpdateCallLogDto,
    Notification,
    JobMatch,
    SuccessfulLoginResponse,
    InviteAdminUserDto,
} from '../types';
class API {
    async login(creds: LoginArgs) {
        const res = await axiosInstance.request<SuccessfulLoginResponse>({
            method: 'post',
            url: '/login',
            data: creds,
        });
        return res.data;
    }

    async appleLogin(creds: any) {
        const res = await axiosInstance.request<SuccessfulLoginResponse>({
            method: 'post',
            url: '/apple-login',
            data: creds,
        });
        return res.data;
    }

    async googleLogin(creds: any) {
        const res = await axiosInstance.request<SuccessfulLoginResponse>({
            method: 'post',
            url: '/google-login',
            data: creds,
        });
        return res.data;
    }

    async whoami() {
        const res = await axiosInstance.request<User>({
            method: 'get',
            url: '/users/whoami',
        });
        return res.data;
    }

    async inviteAdmin(dto: InviteAdminUserDto) {
        const res = await axiosInstance.request<Admin>({
            method: 'post',
            url: '/admins',
            data: dto,
        });
        return res.data;
    }

    async requestPasswordReset(email: string) {
        const res = await axiosInstance.request({
            method: 'post',
            url: '/forgot-password',
            data: { email },
        });
        return res.data;
    }

    async checkVerificationCode(email: string, code: string) {
        const res = await axiosInstance.request<boolean>({
            method: 'post',
            url: '/verify-code',
            data: { email, code },
        });
        return res.data;
    }

    async resetPassword({
        email,
        code,
        newPassword,
    }: {
        email: string;
        code: string;
        newPassword: string;
    }) {
        const res = await axiosInstance.request<boolean>({
            method: 'post',
            url: '/reset-password',
            data: { email, code, newPassword },
        });
        return res.data;
    }

    async createAccount(userToCreate: CreateAccountArgs) {
        const res = await axiosInstance.request<{ user: User }>({
            method: 'post',
            url: '/applicants',
            data: userToCreate,
        });
        return res.data;
    }

    async checkIfExists(emailToCheck: string) {
        const res = await axiosInstance.request<boolean>({
            method: 'get',
            url: `/users/email/${emailToCheck}`,
        });
        return res.data;
    }

    async getApplicant(id: string) {
        const res = await axiosInstance.request<Applicant>({
            method: 'get',
            url: `/applicants/${id}`,
        });
        return res.data;
    }

    async getProfileCompletion(id: string) {
        const res = await axiosInstance.request<string[]>({
            method: 'get',
            url: `/applicants/${id}/profile-completion`,
        });
        return res.data;
    }

    async getAllApplicants() {
        const res = await axiosInstance.request<Applicant[]>({
            method: 'get',
            url: '/applicants',
        });
        return res.data;
    }

    async updateApplicant(id: string, dto: UpdateApplicantDto) {
        const res = await axiosInstance.request({
            method: 'patch',
            url: `/applicants/${id}`,
            data: dto,
        });
        return res.data;
    }

    async getJobHistory(applicantId: string) {
        const res = await axiosInstance.request<JobHistory[]>({
            method: 'get',
            url: `/job-history/${applicantId}`,
        });
        return res.data;
    }

    async createJobHistory(applicantId: string, dto: CreateJobHistoryDto) {
        const res = await axiosInstance.request<{ user: User }>({
            method: 'post',
            url: `/job-history/${applicantId}`,
            data: dto,
        });
        return res.data;
    }

    async deleteJobHistoryRecord(applicantId: string, jobHistoryId: string) {
        const res = await axiosInstance.request({
            method: 'delete',
            url: `/job-history/${applicantId}/${jobHistoryId}`,
        });
        return res.data;
    }

    async updateAdmin(adminId: string, dto: UpdateAdminUserDto) {
        const res = await axiosInstance.request<Admin>({
            method: 'patch',
            url: `/admins/${adminId}`,
            data: dto,
        });
        return res.data;
    }

    async getAdmin(adminId: string) {
        const res = await axiosInstance.request<Admin>({
            method: 'get',
            url: `/admins/${adminId}`,
        });
        return res.data;
    }

    async getAllAdmins() {
        const res = await axiosInstance.request<Admin[]>({
            method: 'get',
            url: '/admins',
        });
        return res.data;
    }

    async disableAdmin(id: string) {
        const res = await axiosInstance.request({
            method: 'delete',
            url: `/admins/${id}`,
        });
        return res.data;
    }

    async getAllCounties() {
        const res = await axiosInstance.request<County[]>({
            method: 'get',
            url: '/counties',
        });
        return res.data;
    }

    async createJobCategory(dto: CreateJobCategoryDto) {
        const res = await axiosInstance.request<JobCategory>({
            method: 'post',
            url: '/job-categories',
            data: dto,
        });
        return res.data;
    }

    async getJobCategories() {
        const res = await axiosInstance.request<JobCategory[]>({
            method: 'get',
            url: '/job-categories',
        });
        return res.data;
    }

    async getJobCategory(id: string) {
        const res = await axiosInstance.request<JobCategory>({
            method: 'get',
            url: `/job-categories/${id}`,
        });
        return res.data;
    }

    async updateJobCategory(id: string, dto: UpdateJobCategoryDto) {
        const res = await axiosInstance.request<JobCategory>({
            method: 'patch',
            url: `/job-categories/${id}`,
            data: dto,
        });
        return res.data;
    }

    async disableJobCategory(id: string) {
        const res = await axiosInstance.request({
            method: 'delete',
            url: `/job-categories/${id}`,
        });
        return res.data;
    }

    async getClients() {
        const res = await axiosInstance.request<Client[]>({
            method: 'get',
            url: '/clients',
        });
        return res.data;
    }

    async getClient(id: string) {
        const res = await axiosInstance.request<Client>({
            method: 'get',
            url: `/clients/${id}`,
        });
        return res.data;
    }

    async createClient(dto: CreateClientDto) {
        const res = await axiosInstance.request<Client>({
            method: 'post',
            url: '/clients',
            data: dto,
        });
        return res.data;
    }

    async updateClient(id: string, dto: UpdateClientDto) {
        const res = await axiosInstance.request({
            method: 'patch',
            url: `/clients/${id}`,
            data: dto,
        });
        return res.data;
    }

    async disableClient(id: string) {
        const res = await axiosInstance.request({
            method: 'delete',
            url: `/clients/${id}`,
        });
        return res.data;
    }

    async createJob(dto: CreateJobDto) {
        const res = await axiosInstance.request<Job>({
            method: 'post',
            url: '/jobs',
            data: dto,
        });
        return res.data;
    }

    async getJobs() {
        const res = await axiosInstance.request<Job[]>({
            method: 'get',
            url: '/jobs',
        });
        return res.data;
    }

    async getJob(id: string) {
        const res = await axiosInstance.request<Job>({
            method: 'get',
            url: `/jobs/${id}`,
        });
        return res.data;
    }

    async updateJob(id: string, dto: UpdateJobDto) {
        const res = await axiosInstance.request<Job>({
            method: 'patch',
            url: `/jobs/${id}`,
            data: dto,
        });
        return res.data;
    }

    async disableJob(id: string) {
        const res = await axiosInstance.request({
            method: 'delete',
            url: `/jobs/${id}`,
        });
        return res.data;
    }

    // JOB MATCHES
    async getJobMatchesByApplicant(id: string) {
        const res = await axiosInstance.request<JobMatch[]>({
            method: 'get',
            url: `/job-matches/applicant/${id}`,
        });
        return res.data;
    }

    async getJobMatchesByJob(id: string) {
        const res = await axiosInstance.request<JobMatch[]>({
            method: 'get',
            url: `/job-matches/job/${id}`,
        });
        return res.data;
    }

    async getJobMatchesCount() {
        const res = await axiosInstance.request<number>({
            method: 'get',
            url: '/job-matches/match-count',
        });
        return res.data;
    }

    async manualMatch(id: string, selectedApplicants: Applicant[]) {
        const res = await axiosInstance.request({
            method: 'post',
            url: `/job-matches/manual-match/${id}`,
            data: selectedApplicants,
        });
        return res.data;
    }
    // END JOB MATCHES

    // CALL LOG
    async createCallLog(dto: CreateCallLogDto) {
        const res = await axiosInstance.request({
            method: 'post',
            url: '/call-log',
            data: dto,
        });
        return res.data;
    }

    async getAllCallLogs() {
        const res = await axiosInstance.request<CallLog[]>({
            method: 'get',
            url: '/call-log',
        });
        return res.data;
    }

    async getCallLog(id: string) {
        const res = await axiosInstance.request<CallLog>({
            method: 'get',
            url: `/call-log/${id}`,
        });
        return res.data;
    }

    async updateCallLog(id: string, dto: UpdateCallLogDto) {
        const res = await axiosInstance.request({
            method: 'patch',
            url: `/call-log/${id}`,
            data: dto,
        });
        return res.data;
    }
    // END CALL LOG

    // NOTIFICATIONS
    async getNotifications() {
        const res = await axiosInstance.request<Notification[]>({
            method: 'get',
            url: '/app-notifications',
        });
        return res.data;
    }

    async markNotificationAsRead(id: string) {
        const res = await axiosInstance.request({
            method: 'patch',
            url: `/app-notifications/${id}`,
        });
        return res.data;
    }

    async updatePushToken(token: string) {
        const res = await axiosInstance.request({
            method: 'post',
            url: '/app-notifications/token',
            data: { token },
        });
        return res.data;
    }
}

export const api = new API();
