import React from 'react';
import { AuthProvider } from './auth';
import { QueryProvider } from './query';
import { Provider as PaperProvider } from 'react-native-paper';
import PaperTheme from '../constants/PaperTheme';
//Paper provider allows us to set custom theme for paper components (i.e. date/time pickers)
export function AppProviders(props: { children?: React.ReactNode }) {
    return (
        <QueryProvider>
            <AuthProvider>
                <PaperProvider theme={PaperTheme}>
                    {props.children}
                </PaperProvider>
            </AuthProvider>
        </QueryProvider>
    );
}

export { AuthProvider, QueryProvider };
