export default {
	theme: {
		backgroundColor: '#fff',
		black: '#000',
		orange: '#DB3B32',
		lightOrange: '#F58634',
		white: '#fff',
		text: '#000',
		lightGray: '#BCC8D3',
		gray: '#808080',
		error: '#DB3B32',
		green: '#29B360',
		lightBlue: '#2187FF'
	}
}