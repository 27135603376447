import ThemedModal from './ThemedModal';
import { View, Text } from 'react-native';
import CtlTextInput from './ControlledInputs/CtlTextInput';
import CtlSelectInput from './ControlledInputs/CtlSelectInput';
import ThemedButton from './ThemedButton';
import { required } from '../util/formRules';
import Theme from '../constants/Theme';
import unitedStates from '../util/unitedStates';
import { Control } from 'react-hook-form';
import { UpdateJobDto } from '../types';

interface JobAddressModalProps {
    show: boolean;
    control: Control<UpdateJobDto>;
    onClose: () => void;
}

const JobAddressModal: React.FC<JobAddressModalProps> = ({
    show,
    control,
    onClose,
}) => {
    return (
        <ThemedModal
            animationType="slide"
            transparent={true}
            visible={show}
            onRequestClose={onClose}
        >
            <View>
                <Text style={{ fontSize: 20, fontWeight: '600' }}>
                    Job Address
                </Text>
            </View>
            <CtlTextInput
                name="address.addressOne"
                control={control}
                rules={required}
                label="Address Line 1"
                textContentType="streetAddressLine1"
                placeholder="Address Line 1"
            />
            <CtlTextInput
                name="address.addressTwo"
                control={control}
                label="Address Line 2 - optional"
                textContentType="streetAddressLine2"
                placeholder="Address Line 2 - optional"
            />
            <CtlTextInput
                name="address.city"
                control={control}
                rules={required}
                label="City"
                textContentType="addressCity"
                placeholder="City"
            />
            <CtlSelectInput
                name="address.state"
                control={control}
                label="State"
                items={unitedStates}
                rules={required}
            />
            <CtlTextInput
                name="address.zipCode"
                control={control}
                rules={required}
                label="Zip Code"
                textContentType="postalCode"
                placeholder="Zip Code"
            />
            <View style={Theme.modalActionsContainer}>
                <ThemedButton
                    title="Save"
                    color="black"
                    style={Theme.modalActionButton}
                    onPress={onClose}
                />
            </View>
        </ThemedModal>
    );
};

export default JobAddressModal;
