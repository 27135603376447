import { useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    Image,
    TouchableOpacity,
    Platform,
} from 'react-native';
import Theme from '../constants/Theme';
import ThemedButton from '../components/ThemedButton';
import CtlTextInput from '../components/ControlledInputs/CtlTextInput';
import Colors from '../constants/Colors';
import { LoginArgs } from '../types';
import { useAuth } from '../providers/auth';
import { useForm } from 'react-hook-form';
import { required } from '../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { PublicStackNavigationProps } from '../navigation/types';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as WebBrowser from 'expo-web-browser';
import ThemedScrollView from '../components/ThemedScrollView';
import Logo from '../assets/Logo';

export default function LoginScreen({ route }: any) {
    const { control, handleSubmit } = useForm<LoginArgs>({
        defaultValues: {
            email: '',
            password: '',
        },
    });

    const [errMsg, setErrMsg] = useState('');
    const { login, appleLogin, promptGoogleLogin } = useAuth();
    const navigation = useNavigation<PublicStackNavigationProps>();
    WebBrowser.maybeCompleteAuthSession(); // This is needed for Google Login https://docs.expo.dev/guides/google-authentication/#add-a-method-to-dismiss-the-web-popup

    const submitLogin = async (d: LoginArgs) => {
        try {
            setErrMsg('');
            await login(d);
        } catch (err) {
            setErrMsg('Invalid email or password');
        }
    };

    const submitAppleLogin = async () => {
        try {
            setErrMsg('');
            await appleLogin();
        } catch (err: any) {
            if (err.code === 'ERR_REQUEST_CANCELED') return; // Don't show error if user cancels Apple login
            setErrMsg('Error logging in with Apple ID');
        }
    };

    const submitGoogleLogin = async () => {
        try {
            setErrMsg('');
            await promptGoogleLogin();
        } catch (err: any) {
            setErrMsg('Error logging in with Google');
        }
    };

    return (
        <ThemedScrollView>
            <View style={[Theme.pageContent, { marginTop: 15 }]}>
                <View
                    style={[
                        Theme.headerContainerCenter,
                        { flexDirection: 'column', alignItems: 'center', marginBottom: 0 },
                    ]}
                >
                    <Text style={styles.headerWelcome}>Welcome back to</Text>
					<Logo width={300} height={125} />
                </View>
                <View style={Theme.formContainer}>
                    <Text style={styles.formLabel}>Sign In</Text>
                    <CtlTextInput
                        name="email"
                        control={control}
                        rules={required}
                        placeholder="Email Address"
                        textContentType="emailAddress"
                    />
                    <CtlTextInput
                        name="password"
                        control={control}
                        rules={required}
                        textContentType="password"
                        secureTextEntry={true}
                        placeholder="Password"
                    />
                    <Text
                        style={[
                            Theme.mutedText,
                            {
                                fontSize: 12,
                                textDecorationLine: 'underline',
                                marginLeft: 5,
                            },
                        ]}
                        onPress={() => navigation.navigate('PasswordReset', {})}
                    >
                        Forgot password?
                    </Text>
                    <Text style={Theme.errMsg}>{errMsg}</Text>
                    <ThemedButton
                        onPress={handleSubmit(submitLogin)}
                        title="Sign In"
                        variant="large"
                        color="black"
                        style={styles.signInButton}
                    />
                </View>
                <View style={styles.newAccountContainer}>
                    <Text
                        style={StyleSheet.flatten([
                            styles.newAccountText,
                            styles.newAccountTextBold,
                        ])}
                    >
                        Don't have an account?
                    </Text>
                    <Text
                        style={StyleSheet.flatten([
                            styles.newAccountText,
                            styles.signInLink,
                        ])}
                        onPress={() => navigation.navigate('CreateAccount')}
                    >
                        Create Account
                    </Text>
                </View>
                <View style={styles.passportMethodContainer}>
                    {Platform.OS === 'ios' && (
                        <AppleAuthentication.AppleAuthenticationButton
                            buttonType={
                                AppleAuthentication
                                    .AppleAuthenticationButtonType.SIGN_IN
                            }
                            buttonStyle={
                                AppleAuthentication
                                    .AppleAuthenticationButtonStyle
                                    .WHITE_OUTLINE
                            }
                            cornerRadius={50}
                            onPress={submitAppleLogin}
                            style={styles.passportMethodButton}
                        />
                    )}
                    <TouchableOpacity
                        style={[
                            styles.passportMethodButton,
                            styles.passportMethodButtonBorder,
                        ]}
                        onPress={submitGoogleLogin}
                    >
                        <Image
                            style={styles.passportMethodLogo}
                            source={require('../assets/google-icon.png')}
                        />
                        <Text style={styles.passportMethodText}>
                            Sign in with Google
                        </Text>
                    </TouchableOpacity>
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    headerContainer: {
        alignItems: 'center',
    },
    headerWelcome: {
        fontSize: 28,
        fontWeight: '600',
    },
    headerJob: {
        color: Colors.theme.orange,
        fontWeight: '700',
        fontSize: 50,
    },
    headerGig: {
        color: Colors.theme.black,
        fontWeight: '500',
        fontSize: 50,
    },
    formLabel: {
        fontSize: 32,
        marginBottom: 20,
        fontWeight: '500',
        textAlign: 'center',
    },
    formInput: {
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    subHeaderLets: {
        color: Colors.theme.black,
    },
    subHeaderWork: {
        color: Colors.theme.orange,
    },
    signInButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    newAccountContainer: {
        marginVertical: 35,
    },
    newAccountText: {
        fontSize: 16,
        textAlign: 'center',
    },
    newAccountTextBold: {
        fontWeight: 'bold',
        textAlign: 'center',
    },
    signInLink: {
        textDecorationLine: 'underline',
    },
    passportMethodContainer: {
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
        alignItems: 'center',
        width: '100%',
    },
    passportMethodButton: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        height: 50,
        borderRadius: 50,
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: 20,
        fontStyle: 'bold',
    },
    passportMethodButtonBorder: {
        borderWidth: 0.8,
        borderColor: Colors.theme.black,
    },
    passportMethodLogo: {
        height: 15,
        width: 15,
        resizeMode: 'contain',
    },
    passportMethodText: {
        marginLeft: 7.5,
        fontSize: 15,
        color: Colors.theme.black,
        fontWeight: '500',
        textAlign: 'center',
    },
});
