import React from 'react';
import {
    NavigationContainer,
    DefaultTheme,
    DarkTheme,
} from '@react-navigation/native';
import { createNativeStackNavigator } from '@react-navigation/native-stack';
import { ColorSchemeName } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import LinkingConfiguration from './LinkingConfiguration';
import {
    PublicStackParamList,
    ApplicantProfileStackParamList,
    AdminTabsNavigationParamList,
    ApplicantTabsNavigationParamList,
    AdminDashboardStackParamList,
    AdminCallLogStackParamList,
    ProtectedStackParamList,
} from './types';
import { useAuth } from '../hooks';
//Screens
import WelcomeScreen from '../screens/WelcomeScreen';
import LoginScreen from '../screens/LoginScreen';
import ApplicantDashboardScreen from '../screens/Applicant/ApplicantDashboardScreen';
import CreateAccount from '../screens/CreateAccountScreen';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import ProfileScreen from '../screens/Applicant/ApplicantProfileScreen';
import BaseHeader from '../components/BaseHeader';
import { User, UserRole } from '../types/User';
import AdminDashboardScreen from '../screens/Admin/AdminDashboardScreen';
import AdminManageApplicantsScreen from '../screens/Admin/AdminManageApplicantsScreen';
import AdminManageUsersScreen from '../screens/Admin/AdminManageUsers';
import ContactScreen from '../screens/Applicant/CreateProfile/ContactScreen';
import EligibilityScreen from '../screens/Applicant/CreateProfile/EligibilityScreen';
import JobHistoryScreen from '../screens/Applicant/CreateProfile/JobHistoryScreen';
import EducationScreen from '../screens/Applicant/CreateProfile/EducationScreen';
import AccuracyConfirmationScreen from '../screens/Applicant/CreateProfile/AccuracyConfirmationScreen';
import JobPreferencesScreen from '../screens/Applicant/CreateProfile/JobPreferencesScreen';
import AdminEditUserScreen from '../screens/Admin/AdminEditUserScreen';
import AdminManageJobCategoriesScreen from '../screens/Admin/AdminManageJobCategoriesScreen';
import AdminManageClientsScreen from '../screens/Admin/AdminManageClientsScreen';
import AdminEditClientScreen from '../screens/Admin/AdminEditClientScreen';
import AdminManageJobsScreen from '../screens/Admin/AdminManageJobsScreen';
import AdminEditJobScreen from '../screens/Admin/AdminEditJobScreen';
import ApplicantJobMatchesScreen from '../screens/Applicant/ApplicantJobMatchesScreen';
import AdminCallLogScreen from '../screens/Admin/AdminCallLogScreen';
import AdminEditCallLogScreen from '../screens/Admin/AdminEditCallLogScreen';
import AdminManualCallLogScreen from '../screens/Admin/AdminManualCallLogScreen';
import NotificationsModal from '../components/NotificationsModal';
import UserSettingsScreen from '../screens/CommonScreens/UserSettingsScreen';
import AdminViewJobMatchesScreen from '../screens/Admin/AdminViewJobMatchesScreen';
import AdminManualMatchScreen from '../screens/Admin/AdminManualMatchScreen';
import AdminViewApplicantScreen from '../screens/Admin/AdminViewApplicantScreen';
import AdminViewContactScreen from '../screens/Admin/ViewApplicant/AdminViewContactScreen';
import AdminViewEducationScreen from '../screens/Admin/ViewApplicant/AdminViewEducationScreen';
import AdminViewEligibilityScreen from '../screens/Admin/ViewApplicant/AdminViewEligibilityScreen';
import AdminViewJobHistoryScreen from '../screens/Admin/ViewApplicant/AdminViewJobHistoryScreen';
import AdminViewJobPreferencesScreen from '../screens/Admin/ViewApplicant/AdminViewJobPreferencesScreen';
import AdminViewAccuracyConfirmationScreen from '../screens/Admin/ViewApplicant/AdminViewAccuracyConfirmationScreen';
import ApplicantExploreScreen from '../screens/Applicant/ApplicantExploreScreen';
import PasswordResetScreen from '../screens/PasswordResetScreen';
import useNotifs from '../hooks/useNotifs';

const TAB_NAVIGATOR_OPTIONS = {
    tabBarStyle: {
        height: 90,
        paddingTop: 10,
    },
    tabBarActiveTintColor: 'tomato',
    tabBarInactiveTintColor: 'white',
};

/**
/*If you follow the nested navigation down, you'll notice that we 'bubble-down' the header control to the lowest level of the stack.
/*This way, we can have as much control as possible over each screen and not have to worry about headers
/*appearing multiple times (or not at all)
/*In the tab navigators, some tabs will render only a single screen (we define the header),
/*while others will render a stack navigator (we don't define the header, it's defined 'lower' in the stack navigator)
*/
export default function Navigation({
    colorScheme,
}: {
    colorScheme: ColorSchemeName;
}) {
    const { user } = useAuth();
    return (
        <NavigationContainer
            linking={LinkingConfiguration}
            theme={colorScheme === 'dark' ? DarkTheme : DefaultTheme}
        >
            {user ? (
                <ProtectedStackNavigator user={user} />
            ) : (
                <PublicStackNavigator />
            )}
        </NavigationContainer>
    );
}

const ProtectedStack = createNativeStackNavigator<ProtectedStackParamList>();
function ProtectedStackNavigator({ user }: { user: User }) {
    const notifs = useNotifs();
    if (!user) return null;
    return (
        <ProtectedStack.Navigator>
            <ProtectedStack.Group>
                {[UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                    user?.role as UserRole,
                ) ? (
                    <ProtectedStack.Screen
                        name="AdminTabs"
                        options={{ headerShown: false }}
                    >
                        {() => <AdminTabs user={user} />}
                    </ProtectedStack.Screen>
                ) : (
                    <ProtectedStack.Screen
                        name="ApplicantTabs"
                        options={{ headerShown: false }}
                    >
                        {() => <ApplicantTabs user={user} />}
                    </ProtectedStack.Screen>
                )}
                <ProtectedStack.Screen
                    name="Notifications"
                    component={NotificationsModal}
                    options={{ presentation: 'modal' }}
                />
            </ProtectedStack.Group>
        </ProtectedStack.Navigator>
    );
}

const PublicStack = createNativeStackNavigator<PublicStackParamList>();
function PublicStackNavigator() {
    return (
        <PublicStack.Navigator>
            <PublicStack.Screen
                name="Welcome"
                component={WelcomeScreen}
                options={{ headerShown: false }}
            />
            <PublicStack.Screen
                name="Login"
                component={LoginScreen}
                options={{ headerShown: false }}
            />
            <PublicStack.Screen
                name="CreateAccount"
                component={CreateAccount}
                options={{
                    header: () => <BaseHeader style="light" />,
                }}
            />
            <PublicStack.Screen
                name="PasswordReset"
                component={PasswordResetScreen}
                options={{
                    header: () => <BaseHeader style="light" />,
                }}
            />
        </PublicStack.Navigator>
    );
}

const AdminTab = createBottomTabNavigator<AdminTabsNavigationParamList>();
function AdminTabs({ user }: { user: User }) {
    if (!user || ![UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)) {
        return null;
    }
    return (
        <AdminTab.Navigator
            screenOptions={() => ({
                ...TAB_NAVIGATOR_OPTIONS,
            })}
        >
            <AdminTab.Screen
                name="AdminDashboardTab"
                component={AdminDashboardStackNavigator}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="home-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Dashboard',
                    headerShown: false,
                }}
            />
            <AdminTab.Screen
                name="JobPostings"
                component={AdminManageJobsScreen}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="briefcase-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Job Postings',
                    header: () => <BaseHeader />,
                }}
            />
            <AdminTab.Screen
                name="Applicants"
                component={AdminManageApplicantsScreen}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="person-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Applicants',
                    header: () => <BaseHeader />,
                }}
            />
            <AdminTab.Screen
                name="AdminCallLogTab"
                component={AdminCallLogStackNavigator}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="phone-portrait-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Call Log',
                    headerShown: false,
                }}
            />
        </AdminTab.Navigator>
    );
}

const ApplicantTab =
    createBottomTabNavigator<ApplicantTabsNavigationParamList>();
function ApplicantTabs({ user }: { user: User }) {
    if (!user || ![UserRole.APPLICANT].includes(user.role)) {
        return null;
    }
    return (
        <ApplicantTab.Navigator
            screenOptions={() => ({
                ...TAB_NAVIGATOR_OPTIONS,
            })}
        >
            <ApplicantTab.Screen
                name="ApplicantTabsDashboard"
                component={ApplicantDashboardScreen}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="home-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Dashboard',
                    header: () => <BaseHeader />,
                }}
            />
            <ApplicantTab.Screen
                name="JobMatches"
                component={ApplicantJobMatchesScreen}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="briefcase-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Job Matches',
                    header: () => <BaseHeader />,
                }}
            />
            <ApplicantTab.Screen
                name="Explore"
                component={ApplicantExploreScreen}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="search"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Explore',
                    header: () => <BaseHeader />,
                }}
            />
            <ApplicantTab.Screen
                name="ProfileStack"
                component={ApplicantProfileStackNavigator}
                options={{
                    tabBarIcon: (tabInfo) => (
                        <Ionicons
                            name="person-outline"
                            size={30}
                            color={tabInfo.focused ? 'tomato' : 'white'}
                        />
                    ),
                    tabBarLabel: 'Profile',
                    headerShown: false,
                }}
            />
        </ApplicantTab.Navigator>
    );
}

const ApplicantProfileStack =
    createNativeStackNavigator<ApplicantProfileStackParamList>();
function ApplicantProfileStackNavigator() {
    return (
        <ApplicantProfileStack.Navigator>
            <ApplicantProfileStack.Screen
                name="Profile"
                component={ProfileScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="Contact"
                component={ContactScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="Eligibility"
                component={EligibilityScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="Education"
                component={EducationScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="JobHistory"
                component={JobHistoryScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="JobPreferences"
                component={JobPreferencesScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="AccuracyConfirmation"
                component={AccuracyConfirmationScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
            <ApplicantProfileStack.Screen
                name="Settings"
                component={UserSettingsScreen}
                options={{
                    header: () => <BaseHeader style="dark" />,
                }}
            />
        </ApplicantProfileStack.Navigator>
    );
}

const AdminDashboardStack =
    createNativeStackNavigator<AdminDashboardStackParamList>();
function AdminDashboardStackNavigator() {
    return (
        <AdminDashboardStack.Navigator>
            <AdminDashboardStack.Screen
                name="AdminDashboard"
                component={AdminDashboardScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="EditJob"
                component={AdminEditJobScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ManageJobCategories"
                component={AdminManageJobCategoriesScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ManageClients"
                component={AdminManageClientsScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="EditClient"
                component={AdminEditClientScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ManageUsers"
                component={AdminManageUsersScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="EditUser"
                component={AdminEditUserScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewJobMatches"
                component={AdminViewJobMatchesScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicant"
                component={AdminViewApplicantScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantContact"
                component={AdminViewContactScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantEducation"
                component={AdminViewEducationScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantEligibility"
                component={AdminViewEligibilityScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantJobHistory"
                component={AdminViewJobHistoryScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantJobPreferences"
                component={AdminViewJobPreferencesScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ViewApplicantAccuracy"
                component={AdminViewAccuracyConfirmationScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="ManualMatch"
                component={AdminManualMatchScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminDashboardStack.Screen
                name="Settings"
                component={UserSettingsScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
        </AdminDashboardStack.Navigator>
    );
}

const AdminCallLogStack =
    createNativeStackNavigator<AdminCallLogStackParamList>();
function AdminCallLogStackNavigator() {
    return (
        <AdminCallLogStack.Navigator>
            <AdminCallLogStack.Screen
                name="CallLog"
                component={AdminCallLogScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminCallLogStack.Screen
                name="EditCallLog"
                component={AdminEditCallLogScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
            <AdminCallLogStack.Screen
                name="ManualCallLog"
                component={AdminManualCallLogScreen}
                options={{
                    header: () => <BaseHeader />,
                }}
            />
        </AdminCallLogStack.Navigator>
    );
}
