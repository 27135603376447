import { useState } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant } from '../../../types';
import { api } from '../../../api';
import { AdminDashboardStackScreenProps } from '../../../navigation/types';
import { useQuery } from 'react-query';
import ThemedCheckbox from '../../../components/ThemedCheckbox';
import { format, parseISO } from 'date-fns';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewEligibilityScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantEligibility'>) {
    const [errMsg, setErrMsg] = useState('');
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    useQuery(
        ['applicant', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );
    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Eligibility</Text>
                </View>
                <Text style={Theme.subHeader}>
                    {applicantToView?.firstName} {applicantToView?.lastName}
                </Text>
                <View style={Theme.formContainer}>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Date of Birth</Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.dob &&
                                format(
                                    parseISO(applicantToView.dob.toString()),
                                    'MM/dd/yyyy',
                                )}
                        </Text>
                    </View>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>Basic Eligbility</Text>
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="I confirm I am a US Citizen"
                            value={applicantToView?.usCitizen}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="I confirm I am authorized to work in the US"
                            value={applicantToView?.usAuthorized}
                        />
                    </View>
                    <View style={[Theme.dataContainer, { marginTop: 40 }]}>
                        <Text style={Theme.dataLabel}>
                            Additional Information
                        </Text>
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Have you ever been convicted of a felony?"
                            value={applicantToView?.felon}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Are you willing to undergo a pre-screening drug test if necessary?"
                            value={applicantToView?.drugTest}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Are you willing to provide 3 character references?"
                            value={applicantToView?.characterRefs}
                        />
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    checkbox: {
        marginTop: 14,
    },
});
