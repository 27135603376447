import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import Colors from '../../../constants/Colors';
import { Applicant, UpdateApplicantDto } from '../../../types';
import { useForm } from 'react-hook-form';
import { required } from '../../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import CtlCheckbox from '../../../components/ControlledInputs/CtlCheckbox';
import CtlTextInput from '../../../components/ControlledInputs/CtlTextInput';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function EducationScreen() {
    type EducationFormArgs = Pick<Applicant, 'id' | 'education'>;

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<EducationFormArgs>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();

    const editMutation = useMutation(
        (d: UpdateApplicantDto) => api.updateApplicant(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries('profileCompletion');
            },
        },
    );

    useEffect(() => {
        async function fetchUser() {
            if (!user) return;
            await api
                .getApplicant(user.id)
                .then((res) => {
                    reset(res);
                })
                .catch((err) => {
                    setErrMsg(err);
                });
        }
        fetchUser();
    }, []);

    const submitForm = async (d: UpdateApplicantDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            await editMutation.mutateAsync(d);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Education</Text>
                </View>
                <View style={Theme.formContainer}>
                    <Text style={styles.eligibilityNotice}>
                        Select the box(es) for any education you have completed.
                    </Text>
                    <View style={styles.workAuthContainer}>
                        <CtlCheckbox
                            name="education.highSchool"
                            control={control}
                            label="High school or high school equivalency"
                        />

                        <CtlCheckbox
                            name="education.twoYear"
                            control={control}
                            label="2-year college degree"
                        />
                        <CtlCheckbox
                            name="education.fourYear"
                            control={control}
                            label="4-year college degree"
                        />
                        <CtlCheckbox
                            name="education.vocational"
                            control={control}
                            label="Vocation/Trade School"
                        />
                        <CtlCheckbox
                            name="education.other"
                            control={control}
                            label="Other"
                        />
                        {getValues('education.other') && (
                            <CtlTextInput
                                name="education.otherDescription"
                                control={control}
                                rules={
                                    getValues('education.other')
                                        ? required
                                        : undefined
                                }
                                label="Other Description"
                                placeholder="e.g. Completed AA degree and started my bachelor's but did not complete."
                                multiline={true}
                                maxLength={200}
                            />
                        )}

                        <CtlTextInput
                            name="education.certificates"
                            control={control}
                            label="List any certifications you have achieved (optional)"
                            placeholder="e.g. CDL and Class C license obtained in June 2022."
                            multiline={true}
                            maxLength={200}
                        />
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Save Education & Certificates"
                    variant="fluid"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    eligibilityNotice: {
        fontSize: 16,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 14,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.lightGray,
    },
    workAuthContainer: {
        marginVertical: 20,
    },
});
