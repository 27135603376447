import { useState } from 'react';
import { View, Text, ScrollView, StyleSheet } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant } from '../../../types';
import { api } from '../../../api';
import { AdminDashboardStackScreenProps } from '../../../navigation/types';
import { useQuery } from 'react-query';
import ThemedCheckbox from '../../../components/ThemedCheckbox';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewEducationScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantEducation'>) {
    const [errMsg, setErrMsg] = useState('');
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    useQuery(
        ['applicant', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );
    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Education</Text>
                </View>
                <Text style={Theme.subHeader}>
                    {applicantToView?.firstName} {applicantToView?.lastName}
                </Text>
                <View style={Theme.formContainer}>
                    <View style={Theme.dataContainer}>
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="High school or high school equivalency"
                            value={applicantToView?.education.highSchool}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="2-year college degree"
                            value={applicantToView?.education.twoYear}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="4-year college degree"
                            value={applicantToView?.education.fourYear}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Vocation/Trade School"
                            value={applicantToView?.education.vocational}
                        />
                        <ThemedCheckbox
                            style={styles.checkbox}
                            label="Other"
                            value={applicantToView?.education.other}
                        />
                    </View>
                    {applicantToView?.education.other && (
                        <View style={Theme.dataContainer}>
                            <Text style={Theme.dataLabel}>
                                Other Description
                            </Text>
                            <Text style={Theme.dataValue}>
                                {applicantToView?.education.otherDescription}
                            </Text>
                        </View>
                    )}
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            List any certifications you have achieved
                        </Text>
                        <Text style={Theme.dataValue}>
                            {applicantToView?.education.certificates}
                        </Text>
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    checkbox: {
        marginTop: 10,
    },
});
