import {
    useController,
    FieldValues,
    FieldPath,
    FieldError,
    Controller,
} from 'react-hook-form';
import { useEffect } from 'react';
import { ControlledInputProps } from '../../types/ControlledInput';
import { View, StyleSheet, Text, ViewStyle } from 'react-native';
import { Picker } from '@react-native-picker/picker';
import Colors from '../../constants/Colors';

export type ThemedSelectInputProps = {
    value?: string;
    setValue?: (value: string) => void;
    label?: string;
    error?: FieldError | undefined;
    containerStyle?: ViewStyle;
    items: Record<string, any>; //* item value left as any to allow for multiple types
};

/**
 * A controlled select input component that uses react-hook-form on top of
 * a custom select input component.
 * @param value (optional) - The value of the select input
 * @param setValue (optional) - The function by the same name from react-hook-form
 * @param label (optional) - The label to display above the select input
 * @param error (optional) - The error message to display below the select input
 * @param containerStyle (optional) - The style to apply to the container
 * @param items - The items to display in the select input
 */
export default function CtlSelectInput<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    setValue, // Used to set value as needed outside of Picker
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & ThemedSelectInputProps) {
    const { value, containerStyle, items, label } = rest;

    useEffect(() => {
        //Set initial value to first item in list if no value is otherwise set
        if (setValue && items.length > 0 && !value) {
            setValue(items[0].value);
        }
    }, []);

    useEffect(() => {
        //If current value doesn't exist in our items, then find the first
        //available item's value and set it with onChange.
        if (
            setValue &&
            items.length > 0 &&
            !items.find(({ value: v }: { value: any }) => v === value)
        ) {
            setValue(items[0].value);
        }
    }, [value, items]);

    return (
        <Controller
            control={control}
            name={name}
            rules={rules}
            render={({
                field: { value, onChange, onBlur },
                fieldState: { error },
            }) => (
                <View
                    style={StyleSheet.flatten([
                        containerStyle,
                        styles.defaultContainerStyle,
                    ])}
                >
                    {label && (
                        <View style={styles.labelContainer}>
                            <Text style={styles.label}>{label}</Text>
                        </View>
                    )}
                    <Picker
                        onBlur={onBlur}
                        style={styles.picker}
                        selectedValue={value}
                        onValueChange={onChange}
                    >
                        {items.map((item: any) => (
                            <Picker.Item
                                key={item.value}
                                label={item.label}
                                value={item.value}
                            />
                        ))}
                    </Picker>
                    <Text style={styles.errMsg}>{error && error.message}</Text>
                </View>
            )}
        />
    );
}

const styles = StyleSheet.create({
    defaultContainerStyle: {
        display: 'flex',
        alignSelf: 'stretch',
    },
    labelContainer: {
        flexDirection: 'row',
        marginTop: 10,
    },
    label: {
        fontWeight: 'bold',
        marginBottom: 4,
    },
    picker: {
        backgroundColor: Colors.theme.backgroundColor,
        borderRadius: 5,
        borderColor: Colors.theme.lightGray,
        borderWidth: 1,
        height: 'auto',
    },
    errMsg: {
        color: Colors.theme.error,
    },
});
