import { useState } from 'react';
import { View, Text, ScrollView, StyleSheet, FlatList } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant, JobHistory } from '../../../types';
import { api } from '../../../api';
import { AdminDashboardStackScreenProps } from '../../../navigation/types';
import { useQuery } from 'react-query';
import ThemedCheckbox from '../../../components/ThemedCheckbox';
import { format, parseISO } from 'date-fns';
import Colors from '../../../constants/Colors';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewJobHistoryScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantJobHistory'>) {
    const [errMsg, setErrMsg] = useState('');
    const [jobHistory, setJobHistory] = useState<JobHistory[]>([]);
    const [applicantToView, setApplicantToView] = useState<Applicant>();

    useQuery(
        ['jobHistory', route.params.id],
        () => api.getJobHistory(route.params.id),
        {
            onSuccess: (data) => {
                setJobHistory(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );

    useQuery(
        ['applicants', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );

    const Item = ({ props }: { props: JobHistory }) => (
        <View style={styles.historyListItem}>
            <Text style={styles.historyListItemTitle}>{props.title}</Text>
            <Text style={styles.historyListItemSubtitle}>{props.company}</Text>
            <View style={styles.historyListItemDateContainer}>
                <Text style={styles.historyListItemText}>
                    {format(new Date(props.dateStarted), 'MM/dd/yyyy')}
                </Text>
                <Text style={styles.historyListItemText}>
                    {props.dateEnded &&
                        `- ${format(new Date(props.dateEnded), 'MM/dd/yyyy')}`}
                </Text>
            </View>
        </View>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Job History</Text>
                </View>
                <Text style={Theme.subHeader}>
                    {applicantToView?.firstName} {applicantToView?.lastName}
                </Text>
                <View style={Theme.formContainer}>
                    {jobHistory && jobHistory.length === 0 && (
                        <Text style={Theme.mutedText}>
                            No job history found.
                        </Text>
                    )}
                    {jobHistory && jobHistory.length > 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={jobHistory}
                            renderItem={({ item }) => <Item props={item} />}
                            keyExtractor={(item: JobHistory) => item.id}
                        />
                    )}
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    historyListItem: {
        height: 80,
        shadowColor: '#203947',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 6,
        shadowOpacity: 0.2,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 5,
        textAlign: 'left',
        paddingLeft: 20,
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.theme.white,
    },
    historyListItemLeft: {
        flexBasis: '90%',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    historyListItemRight: {
        flexBasis: '10%',
        justifyContent: 'center',
    },
    historyListItemTitle: {
        fontSize: 16,
        fontWeight: '600',
    },
    historyListItemSubtitle: {
        fontSize: 14,
    },
    historyListItemDateContainer: {
        flexDirection: 'row',
    },
    historyListItemText: {
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
});
