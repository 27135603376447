import { Control } from 'react-hook-form';
import ThemedModal from './ThemedModal';
import { View, Text } from 'react-native';
import CtlTextInput from './ControlledInputs/CtlTextInput';
import ThemedButton from './ThemedButton';
import { required } from '../util/formRules';
import Theme from '../constants/Theme';
import { JobCategory, UpdateJobCategoryDto } from '../types';

interface EditJobCategoryModalProps {
    show: boolean;
    control: Control<UpdateJobCategoryDto>;
    onClose: () => void;
    onSave: () => void;
}
const EditJobCategoryModal: React.FC<EditJobCategoryModalProps> = ({
    show,
    control,
    onClose,
    onSave,
}) => {
    return (
        <ThemedModal
            animationType="slide"
            transparent={true}
            visible={show}
            onRequestClose={onClose}
        >
            <View style={Theme.modalHeader}>
                <Text style={Theme.modalHeaderText}>Edit Category Name</Text>
            </View>
            <View>
                <CtlTextInput
                    control={control}
                    name="name"
                    placeholder="Category Name"
                    rules={required}
                />
            </View>
            <View style={Theme.modalActionsContainer}>
                <ThemedButton
                    title="Save"
                    color="orange"
                    style={Theme.modalActionButton}
                    onPress={onSave}
                />
                <ThemedButton
                    title="Cancel"
                    color="black"
                    onPress={onClose}
                    style={Theme.modalActionButton}
                />
            </View>
        </ThemedModal>
    );
};

export default EditJobCategoryModal;
