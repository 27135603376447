import { StyleSheet, FlatList, ScrollView } from 'react-native';
import { View, Text } from './Themed';
import { useUser, useVersion } from '../hooks';
import Colors from '../constants/Colors';
import { useMutation, useQueryClient, useQuery } from 'react-query';
import { api } from '../api';
import { useNavigation, CommonActions } from '@react-navigation/native';
import { Notification } from '../types';
import Theme from '../constants/Theme';
import { formatDistanceToNow } from 'date-fns';
import ThemedButton from './ThemedButton';
import { useState, useMemo } from 'react';

export default function NotificationsModal() {
    const queryClient = useQueryClient();
    const { user } = useUser();
    const { data: allNotifs } = useQuery(
        ['notifs', user?.id],
        () => api.getNotifications(),
        {
            enabled: !!user?.id,
        },
    );
    const newNotifs = useMemo(
        () => allNotifs?.filter((n) => !n.read),
        [allNotifs],
    );
    const readNotifs = useMemo(
        () => allNotifs?.filter((n) => n.read),
        [allNotifs],
    );
    const navigation = useNavigation();
    const [errMsg, setErrMsg] = useState<string>('');

    const clearNotificationMutation = useMutation(
        (id: string) => api.markNotificationAsRead(id),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['notifs', user?.id]);
            },
        },
    );

    const onPressNotif = (notif: Notification) => {
        try {
            if (notif.context) {
                navigation.dispatch(
                    CommonActions.navigate({
                        name: 'Trip',
                        params: { tripId: JSON.parse(notif.context).tripId },
                    }),
                );
            }
            if (!notif.read) clearNotificationMutation.mutate(notif.id);
        } catch (err) {
            throw err;
        }
    };

    const Item = ({ notif }: { notif: Notification }) => (
        <View>
            <View style={styles.notifListItem}>
                <View style={styles.notifListItemHeader}>
                    <Text style={styles.notifListItemTitle}>
                        {notif.message}
                    </Text>
                    <Text style={styles.notifListItemDate}>
                        {formatDistanceToNow(new Date(notif.createdAt))}
                    </Text>
                </View>
                <View style={styles.notifListItemContent}>
                    <ThemedButton
                        title="View Notification"
                        color="black"
                        variant="small"
                        squared
                        rightArrow
                        onPress={() => onPressNotif(notif)}
                    />
                </View>
            </View>
        </View>
    );

    return (
        <ScrollView style={Theme.page}>
            <View style={Theme.pageContent}>
                <Text>{errMsg}</Text>
                <View style={Theme.headerContainerCenter}>
                    <Text style={Theme.headerTextCenter}>
                        New Notifications
                    </Text>
                </View>

                {newNotifs && newNotifs?.length > 0 && (
                    <FlatList
                        scrollEnabled={false}
                        style={styles.notifList}
                        data={newNotifs}
                        renderItem={({ item }) => <Item notif={item} />}
                        keyExtractor={(item: Notification) => item.id}
                    />
                )}
                {newNotifs && newNotifs?.length == 0 && (
                    <Text style={styles.noNewText}>All caught up!</Text>
                )}

                <View style={Theme.headerContainerCenter}>
                    <Text style={Theme.headerTextCenter}>
                        Read Notifications
                    </Text>
                </View>
                {readNotifs && readNotifs?.length > 0 && (
                    <FlatList
                        scrollEnabled={false}
                        style={styles.notifList}
                        data={readNotifs}
                        renderItem={({ item }) => <Item notif={item} />}
                        keyExtractor={(item: Notification) => item.id}
                    />
                )}
                {newNotifs && newNotifs?.length == 0 && (
                    <Text style={styles.noNewText}>All caught up!</Text>
                )}
                {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                <Text style={[Theme.mutedText, { marginTop: 40 }]}>
                    JobGig Version {useVersion()}
                </Text>
            </View>
        </ScrollView>
    );
}

const styles = StyleSheet.create({
    container: {
        alignContent: 'center',
        textAlign: 'center',
        ...Theme.page,
    },
    headerContainer: {
        alignItems: 'flex-start',
        color: Colors.theme.black,
    },
    headerText: {
        fontWeight: '600',
        fontSize: 24,
        marginBottom: 10,
    },
    noNewText: {
        ...Theme.mutedText,
        marginVertical: 30,
    },
    markAllRead: {
        color: Colors.theme.lightBlue,
        fontSize: 12,
    },
    notifList: {
        marginBottom: 20,
        display: 'flex',
        flexDirection: 'column',
        textAlign: 'center',
        width: '100%',
    },
    notifListItem: {
        display: 'flex',
        flex: 1,
        height: 100,
        borderBottomColor: Colors.theme.lightGray,
        borderBottomWidth: 1,
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingTop: 15,
        width: '100%',
    },
    notifListItemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    notifListItemContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        paddingVertical: 10,
    },
    notifListItemTitle: {
        fontSize: 14,
        fontWeight: '500',
    },
    notifListItemDate: {
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
});
