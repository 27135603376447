import { RouteProp, useNavigation, useRoute } from '@react-navigation/native';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { ScrollView, Text, View } from 'react-native';
import { api } from '../api';
import CtlTextInput from '../components/ControlledInputs/CtlTextInput';
import LoadingComponent from '../components/LoadingComponent';
import ThemedButton from '../components/ThemedButton';
import ThemedScrollView from '../components/ThemedScrollView';
import Theme from '../constants/Theme';
import {
    PublicStackNavigationProps,
    PublicStackParamList,
    PublicStackScreenProps,
} from '../navigation/types';
import { required } from '../util/formRules';

export default function PasswordResetScreen({
    route,
}: PublicStackScreenProps<'PasswordReset'>) {
    const { control, getValues, setValue } = useForm<{
        email: string;
        otp: string;
        newPass: string;
        confirmNewPass: string;
    }>();
    const [errMsg, setErrMsg] = useState<string>('');
    const [formStep, setFormStep] = useState<number>(1);
    const [loading, setLoading] = useState<boolean>(false);
    const navigation = useNavigation<PublicStackNavigationProps>();

    const routeObject =
        useRoute<RouteProp<PublicStackParamList, 'PasswordReset'>>();

    useEffect(() => {
        if (
            routeObject.params &&
            routeObject.params.mode === 'accept' &&
            routeObject.params.id &&
            routeObject.params.email
        ) {
            let decodedEmail = decodeURIComponent(routeObject.params.email);
            setValue('email', decodedEmail);
            setFormStep(2);
        }
    }, [routeObject]);

    async function initiatePasswordReset() {
        setErrMsg('');
        if (!getValues('email')) return setErrMsg('Email address is required.');
        setLoading(true);
        await api
            .requestPasswordReset(getValues('email'))
            .then(() => {
                setLoading(false);
                setFormStep(2);
            })
            .catch((err) => {
                setLoading(false);
                setErrMsg(
                    'Error sending password reset email. Please check your email address and try again. If you signed up with an external provider, you will need to visit your account settings with them to reset your password.',
                );
            });
    }

    async function checkVerificationCode() {
        setErrMsg('');
        if (!getValues('otp') || !getValues('email'))
            return setErrMsg('Verification code and email are required.');
        await api
            .checkVerificationCode(getValues('email'), getValues('otp'))
            .then((validCode) => {
                setLoading(false);
                if (!validCode) {
                    throw new Error();
                }
                setFormStep(3);
            })
            .catch((err) => {
                setLoading(false);
                setErrMsg('Invalid verification code. Please try again.');
            });
    }

    async function resetPassword() {
        setErrMsg('');
        if (!getValues('newPass') || !getValues('confirmNewPass'))
            return setErrMsg('New password and confirmation are required.');
        if (getValues('newPass') !== getValues('confirmNewPass'))
            return setErrMsg('Passwords do not match.');
        await api
            .resetPassword({
                email: getValues('email'),
                newPassword: getValues('newPass'),
                code: getValues('otp'),
            })
            .then((success) => {
                setLoading(false);

                if (!success) {
                    throw new Error();
                }
                setFormStep(4);
            })
            .catch((err) => {
                setLoading(false);
                setErrMsg('Error resetting password. Please try again.');
            });
    }

    return (
        <ThemedScrollView>
            <View style={[Theme.pageContent, { marginTop: 25 }]}>
                {formStep === 1 && !loading && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>
                                Request Password Reset
                            </Text>
                        </View>
                        <View style={Theme.formContainer}>
                            <CtlTextInput
                                name="email"
                                control={control}
                                rules={required}
                                label="Email Address"
                                placeholder="Email Address"
                                textContentType="emailAddress"
                            />
                            <ThemedButton
                                title="Next Step"
                                variant="fluid"
                                color="black"
                                onPress={initiatePasswordReset}
                            />
                        </View>
                    </>
                )}
                {formStep === 2 && !loading && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>
                                Enter Verification Code
                            </Text>
                        </View>
                        <Text>
                            Please check your email for a verification code and
                            enter it below.
                        </Text>
                        <View style={[Theme.formContainer, { marginTop: 25 }]}>
                            <CtlTextInput
                                name="otp"
                                control={control}
                                rules={required}
                                label="Verification Code"
                                placeholder="Verification Code"
                            />
                            <ThemedButton
                                title="Next Step"
                                variant="fluid"
                                color="black"
                                onPress={checkVerificationCode}
                            />
                        </View>
                    </>
                )}
                {formStep === 3 && !loading && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>
                                Enter New Password
                            </Text>
                        </View>
                        <Text>
                            Please enter your new password below. It must be at
                            least 8 characters long.
                        </Text>
                        <View style={[Theme.formContainer, { marginTop: 25 }]}>
                            <CtlTextInput
                                name="newPass"
                                control={control}
                                rules={required}
                                label="New Password"
                                placeholder="New Password"
                                textContentType="password"
                                secureTextEntry={true}
                            />
                            <CtlTextInput
                                name="confirmNewPass"
                                control={control}
                                rules={required}
                                label="Confirm New Password"
                                placeholder="Confirm New Password"
                                textContentType="password"
                                secureTextEntry={true}
                            />

                            <ThemedButton
                                title="Next Step"
                                variant="fluid"
                                color="black"
                                onPress={resetPassword}
                            />
                        </View>
                    </>
                )}
                {formStep === 4 && !loading && (
                    <>
                        <View style={Theme.headerContainer}>
                            <Text style={Theme.headerText}>Success!</Text>
                        </View>
                        <Text>
                            Your password has been reset. Please return to the
                            login page to login with your new password.
                        </Text>
                        <View style={[Theme.formContainer, { marginTop: 25 }]}>
                            <ThemedButton
                                title="Return to Login"
                                variant="fluid"
                                color="black"
                                onPress={() => navigation.navigate('Login')}
                            />
                        </View>
                    </>
                )}
                {loading && <LoadingComponent />}
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}
