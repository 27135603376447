import { useState, useEffect } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
    ScrollView,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { api } from '../../api';
import { AdminDashboardStackProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ThemedButton from '../../components/ThemedButton';
import { Job } from '../../types';
import { useQuery } from 'react-query';
import { isFuture, parseISO } from 'date-fns';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import { useForm } from 'react-hook-form';
import JobPostingModal from '../../components/JobPostingModal';
import { filterSearchResults } from '../../util/helperFunctions';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminManageJobsScreen() {
    const {
        control,
        handleSubmit,
        watch: watchFormValue,
    } = useForm<{ searchQuery: string }>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [jobs, setJobs] = useState<Job[]>([]);
    const [filteredJobs, setFilteredJobs] = useState<Job[]>([]);

    const [showJobPostingModal, setShowJobPostingModal] =
        useState<boolean>(false);
    // Holds the job that the user wants to view in the modal
    const [jobToView, setJobToView] = useState<Job | undefined>();

    useQuery(['jobs'], () => api.getJobs(), {
        onSuccess: (data) => {
            setJobs(data);
        },
        onError: () => {
            setErrMsg('Error fetching jobs');
        },
    });

    useEffect(() => {
        setFilteredJobs(
            filterSearchResults(watchFormValue('searchQuery'), jobs, ['title']),
        );
    }, [watchFormValue('searchQuery'), jobs]);

    function getStyle(deadline?: Date) {
        if (deadline) {
            return isFuture(parseISO(deadline.toString()))
                ? styles.jobItemStatusTextOpen
                : styles.jobItemStatusTextClosed;
        }
        return styles.jobItemStatusTextOpen;
    }

    const Item = (job: Job) => (
        <TouchableOpacity onPress={() => handleOpenJobPostingModal(job)}>
            <View style={styles.jobListItem}>
                <View style={styles.jobItemHeader}>
                    <Text
                        style={StyleSheet.flatten([
                            styles.jobItemTitle,
                            getStyle(job.deadline),
                        ])}
                    >
                        {job.title}
                    </Text>
                    <Text
                        style={StyleSheet.flatten([
                            styles.jobItemStatusText,
                            getStyle(job.deadline),
                        ])}
                    >
                        {job.deadline &&
                        isFuture(parseISO(job.deadline.toString()))
                            ? 'Open'
                            : 'Closed'}
                    </Text>
                </View>
                <View style={styles.jobItemSubheader}>
                    <Text
                        style={StyleSheet.flatten([
                            styles.jobItemSubtitle,
                            getStyle(job.deadline),
                        ])}
                    >
                        {job.jobCategory?.name},{' '}
                        {job.highSchoolReq
                            ? 'HS Diploma Required'
                            : 'No HS Diploma Required'}
                    </Text>
                </View>
                <View style={styles.jobItemContent}>
                    <Text style={styles.jobItemData}>
                        ${job.payRate}
                        {job.payFlexible ? ', Flexible' : ', Fixed'}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );

    function handleOpenJobPostingModal(job: Job) {
        setJobToView(job);
        setShowJobPostingModal(true);
    }

    function handleCloseJobPostingModal() {
        setShowJobPostingModal(false);
        setJobToView(undefined);
    }

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Sort By</Text>
                    <ThemedButton
                        title="New Job"
                        color="black"
                        variant="small"
                        onPress={() => navigation.navigate('EditJob', {})}
                    />
                </View>
                <View style={Theme.searchContainer}>
                    <CtlTextInput
                        name="searchQuery"
                        control={control}
                        rules={required}
                        placeholder="Search"
                    />
                </View>
                <View style={styles.jobListContainer}>
                    {filteredJobs.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredJobs}
                            renderItem={({ item }) => <Item {...item} />}
                            keyExtractor={(item: Job) => item.id}
                        />
                    )}
                    {filteredJobs.length <= 0 && <Text>No jobs found.</Text>}
                </View>
                <Text style={styles.errMsg}>{errMsg}</Text>

                <JobPostingModal
                    visible={showJobPostingModal}
                    job={jobToView}
                    onCloseFunc={handleCloseJobPostingModal}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    jobListContainer: {
        width: '100%',
    },
    jobListItem: {
        display: 'flex',
        height: 100,
        backgroundColor: Colors.theme.white,
        borderRadius: 4,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingHorizontal: 20,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        ...Theme.listItemShadow,
    },
    jobItemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemSubheader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemTitle: {
        fontSize: 18,
        fontWeight: '600',
    },
    jobItemSubtitle: {
        fontSize: 14,
        fontWeight: '500',
    },
    jobItemStatusText: {
        fontSize: 12,
    },
    jobItemStatusTextOpen: {
        color: Colors.theme.black,
    },
    jobItemStatusTextClosed: {
        color: Colors.theme.lightGray,
    },
    jobItemContent: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    jobItemData: {
        marginTop: 5,
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    emailText: {
        fontSize: 14,
        color: Colors.theme.lightGray,
        marginTop: 5,
    },
    fillOutProfileButton: {
        marginTop: 75,
    },
    textOrange: {
        color: Colors.theme.orange,
    },
    errMsg: {
        color: Colors.theme.error,
    },
});
