import { SafeAreaProvider } from 'react-native-safe-area-context';
import { StatusBar } from 'expo-status-bar';
import Navigation from './navigation';
import { AppProviders } from './providers';
import { useFonts } from 'expo-font';

export default function App() {
    const [fontsLoaded] = useFonts({
        Roboto: require('./assets/fonts/Roboto-Regular.ttf'),
    });

    if (!fontsLoaded) {
        return null;
    }

    return (
        <AppProviders>
            <SafeAreaProvider>
                <StatusBar style="light" />
                <Navigation colorScheme="dark" />
            </SafeAreaProvider>
        </AppProviders>
    );
}
