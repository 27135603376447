import ThemedModal from './ThemedModal';
import ThemedButton from './ThemedButton';
import { View, Text, StyleSheet } from 'react-native';
import Theme from '../constants/Theme';

interface DeleteEntityModalProps {
    show: boolean;
    entityType: string;
    onConfirm: () => void;
    onCancel: () => void;
}

const DeleteEntityModal: React.FC<DeleteEntityModalProps> = ({
    show,
    entityType,
    onConfirm,
    onCancel,
}) => {
    return (
        <ThemedModal
            animationType="slide"
            transparent={true}
            visible={show}
            onRequestClose={onCancel}
        >
            <View style={Theme.modalHeader}>
                <Text style={Theme.modalHeaderText}>
                    Are you sure you want to remove this {entityType}?
                </Text>
            </View>
            <View style={Theme.modalActionsContainer}>
                <ThemedButton
                    title="Remove"
                    color="orange"
                    onPress={onConfirm}
                    style={{...Theme.modalActionButton, zIndex: 99}}
                />
                <ThemedButton
                    title="Cancel"
                    color="black"
                    onPress={onCancel}
                    style={Theme.modalActionButton}
                />
            </View>
        </ThemedModal>
    );
};

export default DeleteEntityModal;