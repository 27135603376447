import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../../constants/Theme';
import ThemedButton from '../../../components/ThemedButton';
import CtlRadioButton from '../../../components/ControlledInputs/CtlRadioButton';
import Colors from '../../../constants/Colors';
import { UpdateApplicantDto } from '../../../types';
import { useForm } from 'react-hook-form';
import { required } from '../../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../../api';
import { ApplicantProfileStackProps } from '../../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../../hooks';
import CtlDatePickerInput from '../../../components/ControlledInputs/CtlDatePickerInput';
import CtlCheckbox from '../../../components/ControlledInputs/CtlCheckbox';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function EligibilityScreen() {
    type EligibilityFormArgs = {
        id: string;
        dob: Date;
        usCitizen: boolean;
        usAuthorized: boolean;
        felon: boolean;
        drugTest: boolean;
        characterRefs: boolean;
    };

    const {
        control,
		getValues,
        setValue,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<EligibilityFormArgs>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ApplicantProfileStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();

    const editMutation = useMutation(
        (d: UpdateApplicantDto) => api.updateApplicant(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
                queryClient.invalidateQueries('profileCompletion');
            },
        },
    );

    useEffect(() => {
        async function fetchUser() {
            if (!user) return;
            await api
                .getApplicant(user.id)
                .then((res) => {
                    reset(res);
                })
                .catch((err) => {
                    setErrMsg(err);
                });
        }
        fetchUser();
    }, []);

    const submitForm = async (d: UpdateApplicantDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            await editMutation.mutateAsync(d);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Eligibility</Text>
                </View>
                <View style={Theme.formContainer}>
                    <CtlDatePickerInput
                        name="dob"
                        getValueFn={getValues}
						setValueFn={setValue}
                        rules={required}
                        label="Date of Birth"
                        endDate={new Date()}
                    />

                    <Text style={styles.eligibilityNotice}>
                        The following statements must be true in order to be
                        matched on JobGig.
                    </Text>
                    <View style={styles.workAuthContainer}>
                        <CtlCheckbox
                            name="usCitizen"
                            control={control}
                            rules={required}
                            label="I confirm I am a US citizen"
                            inputRequired={true}
                        />

                        <CtlCheckbox
                            name="usAuthorized"
                            control={control}
                            rules={required}
                            label="I confirm I am authorized to work in the US"
                            inputRequired={true}
                        />
                    </View>
                    <Text style={styles.eligibilityNotice}>
                        The answers to the following questions will not
                        disqualify you from matches.
                    </Text>
                    <CtlRadioButton
                        name="felon"
                        control={control}
                        groupLabel="Have you ever been convicted of a felony?"
                    />
                    <CtlRadioButton
                        name="drugTest"
                        control={control}
                        groupLabel="Are you willing to undergo a pre-screening drug test if necessary?"
                    />
                    <CtlRadioButton
                        name="characterRefs"
                        control={control}
                        groupLabel="Are you willing to provide 3 character references?"
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title="Save Eligibility"
                    variant="fluid"
                    color="black"
                    style={styles.saveEligibilityButton}
                />
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    eligibilityNotice: {
        fontSize: 14,
        fontWeight: '600',
        marginTop: 20,
        textAlign: 'left',
    },
    nextStepButton: {
        marginTop: 8,
        marginBottom: 8,
    },
    saveEligibilityButton: {
        marginTop: 14,
        marginBottom: 20,
    },
    subHeader: {
        fontSize: 20,
        fontWeight: '500',
        lineHeight: 30,
    },
    switchLabel: {
        marginLeft: 5,
        fontSize: 16,
        color: Colors.theme.lightGray,
    },
    workAuthContainer: {
        marginVertical: 10,
    },
});
