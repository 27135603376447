import { useState, useEffect } from 'react';
import { View, Text } from 'react-native';
import Theme from '../../constants/Theme';
import ThemedButton from '../../components/ThemedButton';
import { CreateClientDto, UpdateClientDto } from '../../types';
import { useForm } from 'react-hook-form';
import { required } from '../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../api';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
} from '../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import CtlSelectInput from '../../components/ControlledInputs/CtlSelectInput';
import unitedStates from '../../util/unitedStates';
import DeleteEntityModal from '../../components/DeleteEntityModal';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminEditClientScreen({
    route,
}: AdminDashboardStackScreenProps<'EditClient'>) {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<UpdateClientDto>();
    const [mode, setMode] = useState<'edit' | 'create'>('create');
    const [errMsg, setErrMsg] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigation = useNavigation<AdminDashboardStackProps>();
    const queryClient = useQueryClient();

    useEffect(() => {
        if (route.params.id) {
            setMode('edit');
            loadExistingClient();
            return;
        }
        setMode('create');
    }, [route.params.id]);

    async function loadExistingClient() {
        if (!route.params.id) return;
        await api
            .getClient(route.params.id)
            .then((data) => {
                reset(data);
            })
            .catch((err) => {
                setErrMsg('Error fetching client.');
            });
    }

    const createMutation = useMutation(
        (d: CreateClientDto) => api.createClient(d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['clients']);
                navigation.navigate('ManageClients');
            },
            onError: () => {
                setErrMsg('Error saving client.');
            },
        },
    );

    const editMutation = useMutation(
        (d: UpdateClientDto) => api.updateClient(route.params.id ?? '', d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['clients', route.params.id]);
                navigation.navigate('ManageClients');
            },
            onError: () => {
                setErrMsg('Error updating client.');
            },
        },
    );

    const deleteMutation = useMutation(
        () => api.disableClient(route.params.id ?? ''),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['clients', route.params.id]);
                navigation.navigate('ManageClients');
            },
            onError: () => {
                setErrMsg('Error deleting client.');
            },
        },
    );

    const submitForm = async (d: UpdateClientDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            if (mode === 'create') {
                await createMutation.mutateAsync(d);
                return;
            }
            await editMutation.mutateAsync(d);
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <DeleteEntityModal
                show={showDeleteModal}
                entityType="client"
                onCancel={() => setShowDeleteModal(false)}
                onConfirm={() => deleteMutation}
            />
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        {mode === 'edit' ? 'Edit' : 'Create'} Client
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <CtlTextInput
                        name="name"
                        control={control}
                        rules={required}
                        label="Company Name"
                        placeholder="Company Name"
                    />
                    <CtlTextInput
                        name="address.addressOne"
                        control={control}
                        rules={required}
                        label="Address Line 1"
                        textContentType="streetAddressLine1"
                        placeholder="Address Line 1"
                    />
                    <CtlTextInput
                        name="address.addressTwo"
                        control={control}
                        label="Address Line 2 - optional"
                        textContentType="streetAddressLine2"
                        placeholder="Address Line 2 - optional"
                    />
                    <CtlTextInput
                        name="address.city"
                        control={control}
                        rules={required}
                        label="City"
                        textContentType="addressCity"
                        placeholder="City"
                    />
                    <CtlSelectInput
                        name="address.state"
                        control={control}
                        label="State"
                        items={unitedStates}
                        rules={required}
                    />
                    <CtlTextInput
                        name="address.zipCode"
                        control={control}
                        rules={required}
                        label="Zip Code"
                        textContentType="postalCode"
                        placeholder="Zip Code"
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>

                {mode === 'edit' && (
                    <Text
                        style={Theme.dangerLinkText}
                        onPress={() => setShowDeleteModal(true)}
                    >
                        Delete Client
                    </Text>
                )}
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title={mode === 'edit' ? 'Save Client' : 'Create Client'}
                    variant="fluid"
                    color="black"
                    style={{ marginTop: 20 }}
                />
            </View>
        </ThemedScrollView>
    );
}
