import { useEffect, useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../constants/Theme';
import ThemedButton from '../../components/ThemedButton';
import Colors from '../../constants/Colors';
import { Admin, UpdateApplicantDto } from '../../types';
import { useForm } from 'react-hook-form';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../api';
import { ProtectedStackNavigationProps } from '../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../hooks';
import CtlCheckbox from '../../components/ControlledInputs/CtlCheckbox';
import { UserRole } from '../../types/User';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function UserSettingsScreen() {
    type AdminSettingsArgs = Pick<
        Admin,
        'id' | 'adminNotifSettings' | 'notifSettings'
    >;
    const {
        control,
        handleSubmit,
        reset,
        formState: { isDirty },
    } = useForm<AdminSettingsArgs>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<ProtectedStackNavigationProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();

    const editAdminMutation = useMutation(
        (d: AdminSettingsArgs) => api.updateAdmin(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
            },
        },
    );

    useEffect(() => {
        fetchUser();
    }, []);

    async function fetchUser() {
        if (!user) return;
        if ([UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)) {
            api.getAdmin(user.id)
                .then((res) => {
                    reset(res);
                })
                .catch(() => {
                    setErrMsg('Error fetching user data.');
                });
            return;
        }
        await api
            .getApplicant(user.id)
            .then((res) => {
                reset(res);
            })
            .catch(() => {
                setErrMsg('Error fetching user data.');
            });
    }

    const submitForm = async (d: AdminSettingsArgs) => {
        if (!isDirty) {
            navigation.goBack();
            return;
        }
        try {
            setErrMsg('');
            if (
                user &&
                user.role &&
                [UserRole.ADMIN, UserRole.SUPERADMIN].includes(user.role)
            ) {
                await editAdminMutation.mutateAsync(d);
            }
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating profile.');
        }
    };

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Settings</Text>
                </View>
                <View style={styles.formContainer}>
                    {user && !user.federatedId ? (
                        <View>
                            <Text style={styles.formLabel}>
                                Change Password
                            </Text>
                            <Text style={styles.formSubLabel}>
                                An email will be sent to{' '}
                                <Text style={{ fontWeight: 'bold' }}>
                                    {user?.email}
                                </Text>{' '}
                                with reset instructions.
                            </Text>
                            <ThemedButton
                                title="Send Reset Email"
                                variant="small"
                                color="black"
                                style={styles.resetPassBtn}
                            />
                        </View>
                    ) : (
                        <>
                            <Text style={styles.formLabel}>
                                Change Password
                            </Text>
                            <Text style={styles.formSubLabel}>
                                You are using an exteral login provider. Please
                                visit your account settings with that provider
                                to change your password.
                            </Text>
                        </>
                    )}
                </View>
                {user &&
                    user.role &&
                    [UserRole.ADMIN, UserRole.SUPERADMIN].includes(
                        user.role,
                    ) && (
                        <>
                            <View style={styles.formContainer}>
                                <Text style={styles.formLabel}>
                                    Notification Settings
                                </Text>
                                <Text style={styles.formSubLabel}>
                                    Notify me when:
                                </Text>
                                <View style={styles.checkBoxesContainer}>
                                    <CtlCheckbox
                                        name="notifSettings.newJobMatch"
                                        control={control}
                                        label="A new applicant matches to a job"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.jobListExpired"
                                        control={control}
                                        label="A job listing is expired"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.followUpNeeded"
                                        control={control}
                                        label="It's time to follow up with an applicant"
                                    />
                                    <CtlCheckbox
                                        name="adminNotifSettings.newProfileCompleted"
                                        control={control}
                                        label="A new applicant completes their profile"
                                    />
                                </View>
                            </View>
                            <Text style={Theme.errMsg}>{errMsg}</Text>
                            <View style={{ justifyContent: 'center' }}>
                                <ThemedButton
                                    onPress={handleSubmit(submitForm)}
                                    title="Save"
                                    variant="large"
                                    color="black"
                                />
                            </View>
                        </>
                    )}
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    formContainer: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        textAlign: 'left',
        marginVertical: 10,
    },
    formLabel: {
        fontSize: 18,
        marginBottom: 4,
        fontWeight: 'bold',
    },
    formSubLabel: {
        fontSize: 14,
        fontWeight: '400',
        color: Colors.theme.lightGray,
    },
    formInput: {
        marginBottom: 20,
    },
    checkBoxesContainer: {
        paddingLeft: 10,
        marginTop: 10,
    },
    resetPassBtn: {
        marginTop: 20,
        maxWidth: '50%',
    },
});
