export const required = {
  required: { value: true, message: "This field is required." },
};

export const isEmail = {
  pattern: {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    message: "Invalid email address",
  },
};

export const atLeastOne = {
	required: {
		value: false, message: "Select a response"
	}
};