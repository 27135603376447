import { useEffect, useState } from 'react';
import { View, Text } from 'react-native';
import Theme from '../../constants/Theme';
import ThemedButton from '../../components/ThemedButton';
import { UpdateAdminUserDto } from '../../types';
import { useForm } from 'react-hook-form';
import { required } from '../../util/formRules';
import { useNavigation } from '@react-navigation/native';
import { api } from '../../api';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
} from '../../navigation/types';
import { useQueryClient, useMutation } from 'react-query';
import { useUser } from '../../hooks';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { Admin } from '../../types';
import DeleteEntityModal from '../../components/DeleteEntityModal';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminEditUserScreen({
    route,
}: AdminDashboardStackScreenProps<'EditUser'>) {
    type AdminEditUserFormArgs = Admin;

    const {
        control,
        handleSubmit,
        reset,
        getValues,
        formState: { isDirty },
    } = useForm<AdminEditUserFormArgs>();
    const [mode, setMode] = useState<'edit' | 'create'>('create');
    const [errMsg, setErrMsg] = useState('');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const navigation = useNavigation<AdminDashboardStackProps>();
    const queryClient = useQueryClient();
    const { user } = useUser();

    useEffect(() => {
        if (route.params.id) {
            setMode('edit');
            fetchUser();
            return;
        }
        setMode('create');
    }, []);

    async function fetchUser() {
        try {
            if (!route.params?.id) return;
            //let res = await api.getAdmin(route.params.id);
            //if (!res) throw new Error();
            //reset(res);
        } catch (err) {
            setErrMsg('Error fetching user.');
        }
    }

    const editMutation = useMutation(
        (d: UpdateAdminUserDto) => api.updateAdmin(d.id, d),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['user', user?.id]);
            },
        },
    );

    const submitForm = async (d: UpdateAdminUserDto) => {
        if (!isDirty) {
            navigation.goBack();
        }
        try {
            setErrMsg('');
            if (mode === 'edit') {
                await editMutation.mutateAsync(d);
            } else {
                const { email, firstName, lastName } = d;
                if (!email || !firstName || !lastName) throw new Error();
                await api.inviteAdmin({ email, firstName, lastName });
                queryClient.invalidateQueries(['user', user?.id]);
            }
            navigation.goBack();
        } catch (err) {
            if (mode === 'edit') {
                setErrMsg('Error updating profile.');
            } else {
                setErrMsg(
                    'Error inviting user. Please ensure the email is valid and not already in use.',
                );
            }
        }
    };

    const deleteUser = async () => {
        try {
            if (!route.params.id) return;
            const res = await api.disableAdmin(route.params.id);
            if (!res) throw new Error();
            queryClient.invalidateQueries(['user', user?.id]);
            navigation.navigate('AdminDashboard');
        } catch (err) {
            setErrMsg('Error deleting user.');
        }
    };

    return (
        <ThemedScrollView>
            <DeleteEntityModal
                show={showDeleteModal}
                entityType="User"
                onConfirm={deleteUser}
                onCancel={() => setShowDeleteModal(false)}
            />
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        {mode === 'edit' ? 'Edit' : 'Create'} User
                    </Text>
                </View>
                <View style={Theme.formContainer}>
                    <CtlTextInput
                        name="firstName"
                        control={control}
                        rules={required}
                        label="First Name"
                        placeholder="First Name"
                    />
                    <CtlTextInput
                        name="lastName"
                        control={control}
                        rules={required}
                        label="Last Name"
                        placeholder="Last Name"
                    />
                    <CtlTextInput
                        name="email"
                        control={control}
                        rules={required}
                        label="Email"
                        placeholder="Email"
                    />
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
                {mode === 'edit' && (
                    <Text
                        style={Theme.dangerLinkText}
                        onPress={() => setShowDeleteModal(true)}
                    >
                        Delete User
                    </Text>
                )}
                <ThemedButton
                    onPress={handleSubmit(submitForm)}
                    title={mode === 'edit' ? 'Save User' : 'Invite User'}
                    variant="fluid"
                    color="black"
                />
            </View>
        </ThemedScrollView>
    );
}
