import { JobType } from '../types';

/**
 * @description Filters an array of objects by a search query and search keys
 * @param {string} searchQuery
 * @param {array} items
 * @param {array} searchKeys - array of up to 2 keys to search
 * @returns
 */
export function filterSearchResults<ItemType>(
    searchQuery: string,
    items: ItemType[],
    searchKeys: string[],
): ItemType[] {
    if (
        searchQuery === '' ||
        searchQuery === undefined ||
        searchKeys === undefined ||
        searchKeys.length === 0
    )
        return items;

    const query = searchQuery.toLowerCase();

    if (searchKeys.length === 1) {
        return items.filter((item: any) => {
            if (typeof item[searchKeys[0]] !== 'string') return false; // Don't search non-string values
            return item[searchKeys[0]].toLowerCase().includes(query);
        });
    }

    return items.filter((item: any) => {
        if (
            typeof item[searchKeys[0]] !== 'string' ||
            typeof item[searchKeys[1]] !== 'string'
        )
            return false; // Don't search non-string values

        return (
            item[searchKeys[0]].toLowerCase().includes(query) ||
            item[searchKeys[1]].toLowerCase().includes(query) ||
            (
                item[searchKeys[0]].toLowerCase() +
                ' ' +
                item[searchKeys[1]].toLowerCase()
            ).includes(query)
        );
    });
}

export function capitalizeString(str: string): string {
    return str.charAt(0).toUpperCase() + str.slice(1);
}

export function getJobTypeText(jobType: JobType[]): string {
    if (jobType.length === 1) return jobType[0];
    if (jobType.length === 2) return jobType[0] + ' or ' + jobType[1];
    if (jobType.length === 3)
        return jobType[0] + ', ' + jobType[1] + ', or ' + jobType[2];
    return 'Unknown';
}
