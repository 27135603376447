import {
    useController,
    Controller,
    FieldValues,
    FieldPath,
} from 'react-hook-form';
import { ControlledInputProps } from '../../types/ControlledInput';
import { View, StyleSheet, Text } from 'react-native';
import Slider, { SliderProps } from '@react-native-community/slider';
import Colors from '../../constants/Colors';
import Theme from '../../constants/Theme';

export interface CustomSliderProps extends SliderProps {
    label?: string;
    valuePrefix?: string;
    onChange?: (val: number) => void;
}

/**
 * A controlled slider component that uses react-hook-form on top of
 * a custom slider component also defined here.
 * @Param minimumValue - The minimum value of the slider
 * @Param maximumValue - The maximum value of the slider
 * @Param step - The step (increment) value of the slider
 * @Param onChange - The function to call when the slider value changes
 * @Param label - The label to display above the slider
 * @Param valuePrefix - The prefix to display before the slider value (value is displayed in parentheses after label)
 */
export default function CtlSlider<
    TFieldValues extends FieldValues = FieldValues,
    TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
    name,
    control,
    rules,
    ...rest
}: ControlledInputProps<TFieldValues, TName> & CustomSliderProps) {
    const {
        field,
        fieldState: { error },
    } = useController<TFieldValues, TName>({
        name,
        control,
        rules,
    });
    const { minimumValue, maximumValue, step, onChange, label, valuePrefix } =
        rest;

    return (
        <Controller
            name={name}
            control={control}
            rules={rules}
            render={({ field: { onChange, value } }) => (
                <View>
                    <Text style={Theme.inputLabel}>
                        {label} ({valuePrefix}
                        {value})
                    </Text>
                    <Slider
                        minimumValue={minimumValue}
                        maximumValue={maximumValue}
                        value={value}
                        step={step}
                        minimumTrackTintColor={Colors.theme.orange}
                        maximumTrackTintColor={Colors.theme.lightGray}
                        thumbTintColor={Colors.theme.black}
                        onValueChange={onChange}
                    />
                </View>
            )}
        />
    );
}
