import { Education, JobPreference, JobHistory } from './Applicant';
import { Address } from './Address';

export enum UserRole {
    ADMIN = 'admin',
    APPLICANT = 'applicant',
    SUPERADMIN = 'superadmin',
}

export enum communicationPref {
    TEXT = 'text',
    PHONE = 'phone',
}

export type NotifSettings = {
    newJobMatch: boolean;
};

export type adminNotifSettings = {
    jobListExpired: boolean;
    followUpNeeded: boolean;
    newProfileCompleted: boolean;
};

export type BaseUser = {
    id: string;
    firstName: string;
    middleName?: string;
    lastName: string;
    email: string;
    phone: string;
    role: UserRole;
    federatedId: string;
    notifSettings: NotifSettings;
};

export type Applicant = BaseUser & {
    usCitizen: boolean;
    dob: Date;
    felon: boolean;
    usAuthorized: boolean;
    drugTest: boolean;
    characterRefs: boolean;
    available: boolean;
    accuracyConfirmation: boolean;
    backgroundConsent: boolean;
    commPreference: communicationPref;
    role: UserRole.APPLICANT;
    address: Address;
    education: Education;
    jobPreference: JobPreference;
    jobHistory: JobHistory[];
};

export type Admin = BaseUser & {
    role: UserRole.ADMIN;
    adminNotifSettings: adminNotifSettings;
};

export type SuperAdmin = Omit<Admin, 'role'> & {
    role: UserRole.SUPERADMIN;
};

export type User = Applicant | SuperAdmin | Admin;
