import { forwardRef, ReactElement} from 'react';
import {
    Text,
	TextProps as DefaultTextProps,
    StyleSheet,
} from 'react-native';

import Colors from '../constants/Colors';


export interface JobGigStyledTextProps extends DefaultTextProps {
    size: number;
	theme: 'dark' | 'light';
}

/**
 * Custom component that is used to display the JobGig logo as text
 * @param {number} size - The size of the text
 * @param {string} theme - The theme of the text (dark or light)
*/ 
export default forwardRef<any, JobGigStyledTextProps>(
    (props, ref): ReactElement => {
        const {
			size,
			theme,
            ...jobGigStyledTextProps
        } = props;

		const textSize = {
			fontSize: size
		}

		const textColor = {
			color: theme === 'dark' ? Colors.theme.black : Colors.theme.white
		}

        return (
            <Text style={StyleSheet.flatten([styles.text, textSize])}>
                <Text style={styles.textOrange}>Job</Text>
                <Text style={StyleSheet.flatten([textColor])}>Gig</Text>
            </Text>
        );
    },
);

const styles = StyleSheet.create({
    text: {
        color: Colors.theme.white,
		fontWeight: '600',
    },
    textOrange: {
        color: Colors.theme.orange,
    },
});
