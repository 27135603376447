import { ReactElement } from 'react';
import { View, StyleSheet, ViewStyle } from 'react-native';

import Colors from '../constants/Colors';
import { CallLogStatus } from '../types';

interface StatusDotProps {
    status: CallLogStatus;
    style?: ViewStyle;
}

export default function StatusDot(props: StatusDotProps): ReactElement {
    const getStatusClass = (status: CallLogStatus) => {
        switch (status) {
            case CallLogStatus.NEW_MATCH:
                return styles.new;
            case CallLogStatus.FOLLOW_UP:
                return styles.followUp;
            case CallLogStatus.ANSWERED:
                return styles.answered;
            default:
                return styles.new;
        }
    };

    return (
        <View
            style={StyleSheet.flatten([
                styles.statusDot,
                getStatusClass(props.status),
                props.style,
            ])}
        ></View>
    );
}

const styles = StyleSheet.create({
    statusDot: {
        width: 10,
        height: 10,
        borderRadius: 50,
        margin: 0,
        padding: 0,
    },
    new: {
        backgroundColor: Colors.theme.lightBlue,
    },
    followUp: {
        backgroundColor: Colors.theme.orange,
    },
    answered: {
        backgroundColor: Colors.theme.green,
    },
});
