import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { api } from '../api';
import { User } from '../types';
import { authService } from '../util/authService';

export default function useUser() {
    const {
        data: user,
        isLoading,
        isError,
    } = useQuery<User>('whoami', () => api.whoami(), {
        retry: false,
    });

    useEffect(() => {
        if (isError) {
            authService.clearCredentials();
        } else if (user) {
            authService.setUser(user);
        }
    }, [user, isError]);
    return { user, isLoading };
}
