import React from 'react';
import { Dimensions, StyleSheet, Image, Text } from 'react-native';
import { View } from './Themed';
import Colors from '../constants/Colors';
import Theme from '../constants/Theme';
interface ProfilePictureProps {
    initials?: string;
    imageUrl?: string;
}

/**
 * Reusable component for displaying a profile picture.
 * @param initials Initials to display if no image is provided (optional)
 * @param imageUrl URL of the image to display (optional)
 * If no initials or image URL is provided, only a black circle will be displayed.
 **/
export default function ProfilePicture(props: ProfilePictureProps) {
    return (
        <View style={styles.container}>
            <Text style={styles.initialsText}>{props.initials}</Text>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.theme.black,
        textAlign: 'center',
        justifyContent: 'center',
        height: 50,
        width: 50,
        borderRadius: 25,
        borderWidth: 4,
        borderColor: Colors.theme.orange,
        margin: 5,
    },
    initialsText: {
        color: Colors.theme.white,
        fontSize: 16,
        fontWeight: '600',
        textAlign: 'center',
    },
});
