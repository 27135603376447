import { StyleSheet, ActivityIndicator } from 'react-native';
import { View } from './Themed';
import Colors from '../constants/Colors';
import Theme from '../constants/Theme';
export default function LoadingComponent() {
    return (
        <View style={Theme.pageContent}>
            <ActivityIndicator size="large" color={Colors.theme.orange} />
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        textAlign: 'center',
    },
});
