import { Job } from './Job';
import { Admin, SuperAdmin, Applicant } from './User';
export type CallLog = {
    id: string;
    contactedBy?: Admin | SuperAdmin;
    applicant: Applicant;
    job: Job;
    notes: string;
    status: CallLogStatus;
    remindMe: boolean;
};

export type UpdateCallLogDto = Omit<
    CallLog,
    'contactedBy' | 'applicant' | 'job'
> & {
    contactedBy?: string;
    applicant?: string;
    job?: string;
};
export type CreateCallLogDto = Omit<UpdateCallLogDto, 'id'>;

export enum CallLogStatus {
    NEW_MATCH = 'NEW_MATCH',
    FOLLOW_UP = 'FOLLOW_UP',
    ANSWERED = 'ANSWERED',
}
