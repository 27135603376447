import { useState } from 'react';
import { View, Text } from 'react-native';
import Theme from '../../../constants/Theme';
import { Applicant } from '../../../types';
import { api } from '../../../api';
import { AdminDashboardStackScreenProps } from '../../../navigation/types';
import { useQuery } from 'react-query';
import ThemedCheckbox from '../../../components/ThemedCheckbox';
import ThemedScrollView from '../../../components/ThemedScrollView';

export default function AdminViewAccuracyConfirmationScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewApplicantAccuracy'>) {
    const [errMsg, setErrMsg] = useState('');
    const [applicantToView, setApplicantToView] = useState<
        Applicant | undefined
    >(undefined);

    useQuery(
        ['applicant', route.params.id],
        () => api.getApplicant(route.params.id),
        {
            onSuccess: (data) => {
                setApplicantToView(data);
            },
            onError: (err) => {
                setErrMsg('Error loading applicant data.');
            },
        },
    );
    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        Confirmation of Accuracy & Background Check Consent
                    </Text>
                </View>
                <Text style={Theme.subHeader}>
                    {applicantToView?.firstName} {applicantToView?.lastName}
                </Text>
                <View style={Theme.formContainer}>
                    <View style={Theme.dataContainer}>
                        <Text style={Theme.dataLabel}>
                            By checking the box and continuing, I confirm that
                            the information provided on my profile is accurate
                            and truthful. I also consent to this information
                            being used to perform a background check. I
                            understand that fields left blank must be completed
                            before I will be matched with any jobs.
                        </Text>
                        <ThemedCheckbox
                            style={{ marginTop: 14 }}
                            label="I confirm that I have read and agree with the statement above."
                            value={applicantToView?.accuracyConfirmation}
                        />
                        <ThemedCheckbox
                            style={{ marginTop: 14 }}
                            label="I agree and consent to JobGig running a background check on me."
                            value={applicantToView?.backgroundConsent}
                        />
                    </View>
                </View>
                <Text style={Theme.errMsg}>{errMsg}</Text>
            </View>
        </ThemedScrollView>
    );
}
