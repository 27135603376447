import { useState } from 'react';
import { StyleSheet, View, Text, ScrollView } from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { api } from '../../api';
import { AdminCallLogStackProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ThemedButton from '../../components/ThemedButton';
import { CallLog, CallLogStatus, CreateCallLogDto, Job } from '../../types';
import { useQuery, useMutation } from 'react-query';
import { useForm } from 'react-hook-form';
import CtlRadioButton from '../../components/ControlledInputs/CtlRadioButton';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import CtlCheckbox from '../../components/ControlledInputs/CtlCheckbox';
import { queryClient } from '../../providers/query';
import { useUser } from '../../hooks';
import { SuperAdmin, Admin, Applicant } from '../../types/User';
import CtlSelectInput from '../../components/ControlledInputs/CtlSelectInput';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminManualCallLogScreen() {
    const {
        control,
        handleSubmit,
        reset,
        getValues,
        setValue,
        formState: { isDirty },
    } = useForm<CallLog & { answered: boolean }>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminCallLogStackProps>();
    const { user } = useUser();
    const [applicants, setApplicants] = useState<Applicant[]>([]);
    const [jobs, setJobs] = useState<Job[]>([]);

    useQuery(['applicants', user?.id], () => api.getAllApplicants(), {
        onSuccess: (data) => {
            setApplicants(data);
        },
        onError: (err) => {
            setErrMsg('Error fetching applicants');
        },
    });

    useQuery(['jobs', user?.id], () => api.getJobs(), {
        onSuccess: (data) => {
            setJobs(data);
        },
        onError: (err) => {
            setErrMsg('Error fetching jobs');
        },
    });

    const submitForm = async (d: CallLog) => {
        if (!isDirty) navigation.goBack();
        try {
            setErrMsg('');
            if (getValues().answered) {
                d.status = CallLogStatus.ANSWERED;
            } else {
                d.status = CallLogStatus.FOLLOW_UP;
            }
            if (!getValues().remindMe) d.remindMe = false;

            const dto = {} as CreateCallLogDto;
            Object.assign(dto, {
                ...d,
                contactedBy: user?.id,
                applicant: getValues().applicant,
                job: getValues().job,
            });
            await createMutation.mutateAsync(dto);
            navigation.goBack();
        } catch (err) {
            setErrMsg('Error updating call log');
        }
    };

    const createMutation = useMutation(
        (dto: CreateCallLogDto) => api.createCallLog(dto),
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['call-log']);
            },
        },
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>External Call</Text>
                </View>
                <CtlSelectInput
                    name="applicant.id"
                    control={control}
                    setValue={(val) => setValue('applicant.id', val)}
                    label="Applicant"
                    items={applicants.map((a) => {
                        return {
                            label: `${a.firstName} ${a.lastName}`,
                            value: a.id,
                        };
                    })}
                    rules={required}
                />
                <CtlSelectInput
                    name="job.id"
                    control={control}
                    setValue={(val) => setValue('job.id', val)}
                    label="Job"
                    items={jobs.map((j) => {
                        return {
                            label: j.title,
                            value: j.id,
                        };
                    })}
                    rules={required}
                />
                <CtlRadioButton
                    name="answered"
                    control={control}
                    groupLabel="Did they answer?"
                />
                <CtlTextInput
                    name="notes"
                    control={control}
                    label="Call Notes (optional)"
                    placeholder="Enter notes here... (max 250 chars)"
                    multiline
                    maxLength={250}
                />
                <CtlCheckbox
                    name="remindMe"
                    control={control}
                    label="Remind me to follow up in two weeks"
                />
                <Text style={Theme.errMsg}>{errMsg}</Text>
                <ThemedButton
                    title="Record Call"
                    variant="fluid"
                    color="black"
                    onPress={handleSubmit(submitForm)}
                />
            </View>
        </ThemedScrollView>
    );
}
