import { useEffect, useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    TouchableOpacity,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { api } from '../../api';
import { AdminCallLogStackProps } from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import ThemedButton from '../../components/ThemedButton';
import { CallLog, CallLogStatus } from '../../types';
import { useQuery } from 'react-query';
import StatusDot from '../../components/StatusDot';
import { useUser } from '../../hooks';
import { useForm } from 'react-hook-form';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import { filterSearchResults } from '../../util/helperFunctions';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminCallLogScreen() {
    const { user } = useUser();
    const { control, watch: watchFormValue } = useForm<{
        searchQuery: string;
    }>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminCallLogStackProps>();
    const [newCallLogs, setNewCallLogs] = useState<CallLog[]>([]);
    const [followUpCallLogs, setFollowUpCallLogs] = useState<CallLog[]>([]);
    const [answeredCallLogs, setAnsweredCallLogs] = useState<CallLog[]>([]);
    const [filteredNewCallLogs, setFilteredNewCallLogs] = useState<CallLog[]>(
        [],
    );
    const [filteredFollowUpCallLogs, setFilteredFollowUpCallLogs] = useState<
        CallLog[]
    >([]);
    const [filteredAnsweredCallLogs, setFilteredAnsweredCallLogs] = useState<
        CallLog[]
    >([]);

    useEffect(() => {
        setFilteredNewCallLogs(
            filterSearchResults(watchFormValue('searchQuery'), newCallLogs, [
                'applicant.firstName',
                'applicant.lastName',
            ]),
        );

        setFilteredFollowUpCallLogs(
            filterSearchResults(
                watchFormValue('searchQuery'),
                followUpCallLogs,
                ['applicant.firstName', 'applicant.lastName'],
            ),
        );

        setFilteredAnsweredCallLogs(
            filterSearchResults(
                watchFormValue('searchQuery'),
                answeredCallLogs,
                ['applicant.firstName', 'applicant.lastName'],
            ),
        );
    }, [
        watchFormValue,
        newCallLogs,
        followUpCallLogs,
        answeredCallLogs,
    ]);

    useQuery(['call-log', user?.id], () => api.getAllCallLogs(), {
        onSuccess: (data) => {
            if (data.length > 0) {
                setNewCallLogs(
                    data.filter(
                        (callLog: CallLog) =>
                            callLog.status === CallLogStatus.NEW_MATCH,
                    ),
                );
                setFollowUpCallLogs(
                    data.filter(
                        (callLog: CallLog) =>
                            callLog.status === CallLogStatus.FOLLOW_UP,
                    ),
                );
                setAnsweredCallLogs(
                    data.filter(
                        (callLog: CallLog) =>
                            callLog.status === CallLogStatus.ANSWERED,
                    ),
                );
            }
        },
        onError: () => {
            setErrMsg('Error fetching call logs.');
        },
    });

    const Item = (call: CallLog) => (
        <TouchableOpacity
            onPress={() =>
                [CallLogStatus.NEW_MATCH, CallLogStatus.FOLLOW_UP].includes(
                    call.status,
                )
                    ? navigation.navigate('EditCallLog', { id: call.id })
                    : null
            }
        >
            <View style={styles.listItem}>
                <View style={styles.listItemHeader}>
                    <View style={styles.listItemHeaderLeft}>
                        <Text
                            style={styles.listItemTitle}
                        >{`${call.applicant.firstName} ${call.applicant.lastName}`}</Text>
                    </View>
                    <View style={styles.listItemHeaderRight}>
                        <Text style={styles.listItemStatusText}>
                            {call.contactedBy
                                ? `LC: ${call.contactedBy.firstName} ${call.contactedBy.lastName}`
                                : ''}
                        </Text>
                        <StatusDot
                            status={call.status}
                            style={styles.listItemStatusDot}
                        />
                    </View>
                </View>
                <View style={styles.listItemSubheader}>
                    <Text style={styles.listItemSubtitle}>
                        {call.job.title}
                    </Text>
                </View>
            </View>
        </TouchableOpacity>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Call Log</Text>
                    <Text style={styles.filterText}>Filter</Text>
                </View>
                <View style={Theme.searchContainer}>
                    <CtlTextInput
                        name="searchQuery"
                        control={control}
                        rules={required}
                        placeholder="Search"
                    />
                    <ThemedButton
                        title="Add External Call"
                        color="black"
                        variant="small"
                        style={{ marginTop: 10 }}
                        onPress={() => navigation.navigate('ManualCallLog')}
                    />
                    <Text style={Theme.errMsg}>{errMsg}</Text>
                </View>
                <View style={styles.listContainer}>
                    <Text style={styles.listContainerTitle}>
                        New Job Matches &#40;Not Contacted&#41;:
                    </Text>
                    {filteredNewCallLogs.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredNewCallLogs}
                            renderItem={({ item }) => <Item {...item} />}
                            keyExtractor={(item: CallLog) => item.id}
                        />
                    )}
                    {filteredNewCallLogs.length === 0 && (
                        <Text style={Theme.mutedText}>
                            {watchFormValue('searchQuery') === ''
                                ? 'All caught up!'
                                : 'No results...'}
                        </Text>
                    )}
                </View>
                <View style={styles.listContainer}>
                    <Text style={styles.listContainerTitle}>Follow Up:</Text>
                    {filteredFollowUpCallLogs.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredFollowUpCallLogs}
                            renderItem={({ item }) => <Item {...item} />}
                            keyExtractor={(item: CallLog) => item.id}
                        />
                    )}
                    {filteredFollowUpCallLogs.length === 0 && (
                        <Text style={Theme.mutedText}>
                            {watchFormValue('searchQuery') === ''
                                ? 'All caught up!'
                                : 'No results...'}
                        </Text>
                    )}
                </View>
                <View style={styles.listContainer}>
                    <Text style={styles.listContainerTitle}>
                        Recently Called:
                    </Text>
                    {filteredAnsweredCallLogs.length !== 0 && (
                        <FlatList
                            scrollEnabled={false}
                            data={filteredAnsweredCallLogs}
                            renderItem={({ item }) => <Item {...item} />}
                            keyExtractor={(item: CallLog) => item.id}
                        />
                    )}
                    {filteredAnsweredCallLogs.length === 0 && (
                        <Text style={Theme.mutedText}>
                            {watchFormValue('searchQuery') === ''
                                ? 'No results...'
                                : 'No recent calls...'}
                        </Text>
                    )}
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    titleText: {
        fontSize: 22,
        fontWeight: '700',
    },
    filterText: {
        fontSize: 16,
        textDecorationLine: 'underline',
        paddingTop: 5,
    },
    listContainer: {
        justifyContent: 'flex-start',
        width: '100%',
        marginBottom: 15,
    },
    listContainerTitle: {
        fontSize: 18,
        fontWeight: '500',
        textAlign: 'left',
        marginBottom: 10,
    },
    listItem: {
        display: 'flex',
        height: 60,
        backgroundColor: Colors.theme.white,
        borderRadius: 4,
        marginTop: 5,
        marginBottom: 10,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingHorizontal: 15,
        paddingVertical: 5,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        ...Theme.listItemShadow,
    },
    listItemHeader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItemHeaderLeft: {
        flexDirection: 'row',
        flexBasis: '50%',
    },
    listItemHeaderRight: {
        flexDirection: 'row',
        flexBasis: '50%',
        justifyContent: 'flex-end',
    },
    listItemSubheader: {
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    listItemTitle: {
        fontSize: 18,
        fontWeight: '600',
    },
    listItemSubtitle: {
        fontSize: 14,
    },
    listItemStatusText: {
        fontSize: 12,
    },
    listItemStatusDot: {
        marginLeft: 5,
        marginTop: 4,
    },
});
