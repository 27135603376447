import { useState } from 'react';
import {
    StyleSheet,
    View,
    Text,
    FlatList,
    ScrollView,
    Pressable,
} from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { api } from '../../api';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
} from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import { Job, JobMatch } from '../../types';
import { useQuery } from 'react-query';
import { isFuture, parseISO } from 'date-fns';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminViewJobMatchesScreen({
    route,
}: AdminDashboardStackScreenProps<'ViewJobMatches'>) {
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [job, setJob] = useState<Job>();
    const [jobMatches, setJobMatches] = useState<JobMatch[]>([]);

    useQuery(['jobMatches'], () => api.getJobMatchesByJob(route.params.id), {
        onSuccess: (data) => {
            setJobMatches(data);
        },
        onError: () => {
            setErrMsg('Error fetching matches');
        },
    });

    useQuery('job', () => api.getJob(route.params.id), {
        onSuccess: (data) => {
            setJob(data);
        },
        onError: () => {
            setErrMsg('Error fetching job');
        },
    });

    function getStyle(deadline?: Date) {
        if (deadline) {
            return isFuture(parseISO(deadline.toString()))
                ? styles.jobItemStatusTextOpen
                : styles.jobItemStatusTextClosed;
        }
        return styles.jobItemStatusTextOpen;
    }

    function handleGoToManualMatch() {
        if (!job?.id) return;
        navigation.navigate('ManualMatch', {
            id: job.id,
        });
    }

    const Item = ({ jobMatch }: { jobMatch: JobMatch }) => (
        <Pressable
            style={({ pressed }) => [
                { opacity: pressed ? 0.5 : 1 },
                styles.jobListItem,
            ]}
        >
            <View style={styles.jobItemHeader}>
                <Text style={StyleSheet.flatten([styles.jobItemTitle])}>
                    {jobMatch.applicant.firstName} {jobMatch.applicant.lastName}
                </Text>
            </View>
            <View style={styles.jobItemContent}>
                <Text style={styles.jobItemData}>
                    {`${jobMatch.applicant.address.city}, ${jobMatch.applicant.address.state}`}{' '}
                    | Pref. Min Pay Rate: $
                    {jobMatch.applicant.jobPreference.minimumRate}
                </Text>
            </View>
        </Pressable>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View
                    style={[Theme.headerContainer, { flexDirection: 'column' }]}
                >
                    <Text style={Theme.headerText}>{job?.title}</Text>
                    <Text style={Theme.subHeader}>{job?.client?.name}</Text>
                    <Pressable
                        onPress={() => handleGoToManualMatch()}
                        style={({ pressed }) => [
                            { opacity: pressed ? 0.5 : 1 },
                        ]}
                    >
                        <Text style={[Theme.linkText, { marginVertical: 20 }]}>
                            Match Job to Candidate
                        </Text>
                    </Pressable>
                </View>
                <>{errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}</>
                <View style={styles.jobListContainer}>
                    {jobMatches.length !== 0 && (
                        <>
                            <Text style={styles.listHeader}>Matched</Text>
                            <FlatList
                                scrollEnabled={false}
                                data={jobMatches}
                                renderItem={({ item: jobMatch }) => (
                                    <Item jobMatch={jobMatch} />
                                )}
                                keyExtractor={(jobMatch: JobMatch) =>
                                    jobMatch.id
                                }
                            />
                        </>
                    )}
                    {jobMatches.length <= 0 && (
                        <Text style={Theme.mutedText}>No matches found</Text>
                    )}
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    jobListContainer: {
        width: '100%',
    },
    jobListItem: {
        display: 'flex',
        height: 85,
        marginTop: 6,
        marginBottom: 20,
        marginHorizontal: 4,
        textAlign: 'left',
        paddingHorizontal: 20,
        paddingVertical: 10,
        justifyContent: 'flex-start',
        flexDirection: 'column',
        ...Theme.listItemShadow,
        ...Theme.listItemStyle,
    },
    jobItemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemSubheader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
    },
    jobItemTitle: {
        fontSize: 18,
        fontWeight: '600',
    },
    jobItemSubtitle: {
        fontSize: 14,
        fontWeight: '500',
    },
    jobItemStatusText: {
        fontSize: 12,
    },
    jobItemStatusTextOpen: {
        color: Colors.theme.black,
    },
    jobItemStatusTextClosed: {
        color: Colors.theme.lightGray,
    },
    jobItemContent: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: 5,
    },
    jobItemData: {
        marginTop: 5,
        fontSize: 12,
        color: Colors.theme.lightGray,
    },
    emailText: {
        fontSize: 14,
        color: Colors.theme.lightGray,
        marginTop: 5,
    },
    fillOutProfileButton: {
        marginTop: 75,
    },
    textOrange: {
        color: Colors.theme.orange,
    },
    listHeader: {
        marginVertical: 10,
        marginLeft: 2,
    },
});
