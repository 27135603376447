import { PropsWithChildren, ReactNode } from 'react';
import {
    ImageBackground,
    Modal,
    ModalProps,
    ScrollView,
    View,
} from 'react-native';
import Theme from '../constants/Theme';

/**
 * Themed re-usable modal component
 * @param {ReactNode | ReactNode[]} children
 */
const ThemedModal = (props: PropsWithChildren<ModalProps>) => {
    return (
        <Modal animationType="slide" transparent={true} {...props}>
            <ImageBackground
                style={Theme.modalBlurBackground}
                blurRadius={10}
                source={require('../assets/blur-background.png')}
            >
                <ScrollView style={Theme.modalView}>
                    {props.children}
                </ScrollView>
            </ImageBackground>
        </Modal>
    );
};

export default ThemedModal;
