import { useState } from 'react';
import { StyleSheet, View, Text, Pressable } from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { Ionicons } from '@expo/vector-icons';
import { useAuth } from '../../hooks';
import ThemedButton from '../../components/ThemedButton';
import { useNavigation } from '@react-navigation/native';
import {
    AdminDashboardStackParamList,
    AdminDashboardStackProps,
    AdminTabsNavigationProps,
} from '../../navigation/types';
import { useQuery } from 'react-query';
import { api } from '../../api';
import useHaptics from '../../hooks/useHaptics';
import { UserRole } from '../../types/User';
import ThemedScrollView from '../../components/ThemedScrollView';

export default function AdminDashboardScreen() {
    const [errMsg, setErrMsg] = useState('');
    const { user } = useUser();
    const authService = useAuth();
    const hapticsService = useHaptics('medium');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const tabsNavigation = useNavigation<AdminTabsNavigationProps>();
    const [jobMatchCount, setJobMatchCount] = useState(0);
    type DashboardListItem = {
        key: string;
        title: string;
        iconSource: JSX.Element;
        linkTo: keyof AdminDashboardStackParamList;
    };

    useQuery(['job-match-count', user?.id], () => api.getJobMatchesCount(), {
        onSuccess: (data) => {
            setJobMatchCount(data);
        },
        onError: () => {
            setErrMsg('Error fetching job match count');
        },
    });

    const baseItems: Array<DashboardListItem> = [
        {
            key: '1',
            title: 'Post a New Job',
            iconSource: (
                <Ionicons name="document-outline" size={24} color="black" />
            ),
            linkTo: 'EditJob',
        },
        {
            key: '2',
            title: 'Manage Job Categories',
            iconSource: <Ionicons name="search" size={24} color="black" />,
            linkTo: 'ManageJobCategories',
        },
        {
            key: '3',
            title: 'Manage Clients',
            iconSource: (
                <Ionicons name="briefcase-outline" size={24} color="black" />
            ),
            linkTo: 'ManageClients',
        },
        {
            key: '5',
            title: 'Settings',
            iconSource: (
                <Ionicons name="settings-outline" size={24} color="black" />
            ),
            linkTo: 'Settings',
        },
    ];

    const superAdminItems: Array<DashboardListItem> = [
        {
            key: '4',
            title: 'Manage Admin Users',
            iconSource: (
                <Ionicons name="person-outline" size={24} color="black" />
            ),
            linkTo: 'ManageUsers',
        },
    ];

    const items =
        user?.role === UserRole.SUPERADMIN
            ? [...baseItems, ...superAdminItems]
            : baseItems;

    const handleItemPress = (linkTo: string) => {
        if (hapticsService) {
            hapticsService();
        }
        dashboardScreenNavigation(linkTo);
    };

    const dashboardScreenNavigation = (linkTo: string) => {
        switch (linkTo) {
            case 'EditJob':
                navigation.navigate('EditJob', {});
                break;
            case 'ManageJobCategories':
                navigation.navigate('ManageJobCategories');
                break;
            case 'ManageClients':
                navigation.navigate('ManageClients');
                break;
            case 'ManageUsers':
                navigation.navigate('ManageUsers');
                break;
            case 'Settings':
                navigation.navigate('Settings');
                break;
            default:
                break;
        }
    };

    const Item = ({
        title,
        iconSource,
        linkTo,
    }: {
        title: string;
        iconSource: JSX.Element;
        linkTo: string;
    }) => (
        <Pressable
            onPress={() => handleItemPress(linkTo)}
            style={({ pressed }) => [
                { opacity: pressed ? 0.5 : 1 },
                styles.dashboardListItem,
            ]}
        >
            <View style={styles.dashboardListItemLeft}>
                <>{iconSource}</>
                <Text style={styles.dashboardListItemTitle}>{title}</Text>
            </View>
            <View style={styles.dashboardListItemRight}>
                <Ionicons name="chevron-forward" size={20} color="black" />
            </View>
        </Pressable>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>
                        Welcome back, {user?.firstName}.
                    </Text>
                </View>
                {errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}
                <View style={styles.matchesContainer}>
                    <View style={styles.matchesContent}>
                        <Text style={styles.matchesCount}>{jobMatchCount}</Text>
                        <View style={styles.matchesTitleWrapper}>
                            <Text style={styles.matchesTitle}>
                                Matches Made
                            </Text>
                        </View>
                        <ThemedButton
                            title="Call Matches"
                            color="black"
                            style={styles.callCandidatesBtn}
                            variant="fluid"
                            onPress={() =>
                                tabsNavigation.navigate('AdminCallLogTab')
                            }
                        />
                    </View>
                </View>
                <View style={styles.dashboardListContainer}>
                    {items.map((item) => (
                        <Item
                            key={item.key}
                            title={item.title}
                            iconSource={item.iconSource}
                            linkTo={item.linkTo}
                        />
                    ))}
                </View>
                <View>
                    <Text
                        style={Theme.linkText}
                        onPress={() => authService.logout()}
                    >
                        Log Out
                    </Text>
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    matchesContainer: {
        height: 90,
        alignSelf: 'stretch',
        marginBottom: 30,
        backgroundColor: Colors.theme.white,
        shadowColor: '#203947',
        shadowOffset: { width: 0, height: 1 },
        shadowRadius: 4,
        shadowOpacity: 0.2,
        borderRadius: 4,
        ...Theme.elevation,
    },
    matchesContent: {
        textAlignVertical: 'center',
        flexDirection: 'row',
        justifyContent: 'center',
        alignContent: 'center',
        paddingVertical: 20,
        paddingHorizontal: 10,
        backgroundColor: Colors.theme.white,
    },
    matchesCount: {
        fontSize: 45,
        fontWeight: '700',
        color: Colors.theme.green,
        marginTop: -10,
    },
    matchesTitleWrapper: {
        flex: 0.6,
        marginLeft: 10,
        paddingTop: 7,
    },
    matchesTitle: {
        fontSize: 12,
        fontWeight: '400',
        color: Colors.theme.lightGray,
    },
    callCandidatesBtn: {
        marginLeft: 25,
    },
    dashboardListContainer: {
        width: '100%',
    },
    dashboardListItem: {
        display: 'flex',
        height: 60,
        borderRadius: 4,
        marginTop: 4,
        marginBottom: 20,
        textAlign: 'left',
        paddingLeft: 20,
        justifyContent: 'center',
        flexDirection: 'row',
        backgroundColor: Colors.theme.white,
        ...Theme.listItemShadow,
    },
    dashboardListItemLeft: {
        display: 'flex',
        flexBasis: '90%',
        paddingTop: 15,
        justifyContent: 'flex-start',
        textAlignVertical: 'center',
        flexDirection: 'row',
    },
    dashboardListItemRight: {
        display: 'flex',
        flexBasis: '10%',
        justifyContent: 'center',
    },
    dashboardListItemTitle: {
        paddingLeft: 10,
        fontSize: 18,
        fontWeight: '500',
    },
});
