export class InternalServerError extends Error {
    response: any;
    constructor(message = '', response?: any) {
        super(message);
        this.response = response;
    }
}

export class ClientRequestError extends Error {
    response: any;
    constructor(message = '', response?: any) {
        super(message);
        this.response = response;
    }
}

export class UnauthenticatedError extends ClientRequestError {
    constructor(message = '', response?: any) {
        super(message, response);
    }
}

export class ForbiddenError extends ClientRequestError {
    constructor(message = '', response?: any) {
        super(message, response);
    }
}

export class NotFoundError extends ClientRequestError {
    constructor(message = '', response?: any) {
        super(message, response);
    }
}

export type ErrorResponse<T = any> = {
    statusCode: number;
    name: string;
    message: string;
    details: T;
};

export class BaseErrorResponse<T> extends Error {
    name: string;
    message: string;
    details: T;

    constructor({ name, message, details }: ErrorResponse<T>) {
        super(message);
        this.name = name;
        this.message = message;
        this.details = details;
    }
}
