import {
    Text as DefaultText,
    View as DefaultView,
    TextInput as DefaultTextInput,
    TextInputProps,
    StyleSheet,
} from 'react-native';

import Colors from '../constants/Colors';
import useColorScheme from '../hooks/useColorScheme';

/*
export function useThemeColor(
    props: { theme?: string; },
    colorName: keyof typeof Colors.theme
) {
    const theme = useColorScheme();
    const colorFromProps = props[theme];

    if (colorFromProps) {
        return colorFromProps;
    } else {
        return Colors['theme'][colorName];
    }

	
}
*/

// *JobGig only has one set of theme colors, so this functionality doesn't do much right now, but can be used to expand themes later.
export function Text(props: DefaultText['props']) {
    const { style, ...otherProps } = props;
	const color = Colors['theme']['text'];
    return <DefaultText style={[{ color }, style]} {...otherProps} />;
}

export function View(props: DefaultView['props']) {
    const { style, ...otherProps } = props;
	/*
    const backgroundColor = useThemeColor(
        { light: lightColor, dark: darkColor },
        'background',
    );*/
	const backgroundColor = Colors['theme']['backgroundColor'];
    return <DefaultView style={[{ backgroundColor }, style]} {...otherProps} />;
}
