import {
    createContext,
    useEffect,
    useContext,
    useState,
    ReactNode,
} from 'react';
import { useQueryClient } from 'react-query';
import { User } from '../types/User';
import { authService } from '../util/authService';
import { api } from '../api';
import { LoginArgs } from '../types';
import * as AppleAuthentication from 'expo-apple-authentication';
import * as Google from 'expo-auth-session/providers/google';
import Constants from 'expo-constants';
import { AuthSessionResult } from 'expo-auth-session';
const OAuthClientId = Constants.expoConfig?.extra?.O_AUTH_CLIENT_ID || '';
const ExpoClientId = Constants.expoConfig?.extra?.EXPO_CLIENT_ID || '';

type AuthContextValue = {
    user: User | null;
    login: (creds: LoginArgs) => Promise<void>;
    appleLogin: () => Promise<void>;
    promptGoogleLogin: () => Promise<AuthSessionResult>;
    logout: () => void;
};

// This will never be null in the actual app.
const AuthContext = createContext<AuthContextValue>(
    {} as unknown as AuthContextValue,
);

function AuthProvider(props: { children?: ReactNode }) {
    const [user, setUser] = useState<User | null>(null);
    const queryClient = useQueryClient();

    // STANDARD LOGIN
    const login = async (creds: LoginArgs) => {
        const res = await api.login(creds);
        await authService.storeCredentials(res);
        setUser(res.user);
    };

    // APPLE LOGIN
    const appleLogin = async () => {
        const appleCredentials = await AppleAuthentication.signInAsync({
            requestedScopes: [
                AppleAuthentication.AppleAuthenticationScope.FULL_NAME,
                AppleAuthentication.AppleAuthenticationScope.EMAIL,
            ],
        });

        const res = await api.appleLogin(appleCredentials);
        await authService.storeCredentials(res);
        setUser(res.user);
    };

    // GOOGLE LOGIN
    const [googleRequest, googleResponse, promptGoogleLogin] =
        Google.useIdTokenAuthRequest({
            androidClientId: OAuthClientId,
            iosClientId: OAuthClientId,
            webClientId: OAuthClientId,
            expoClientId: ExpoClientId, // Only needed for development on Expo Go
        });

    useEffect(() => {
        if (googleResponse?.type === 'success') {
            _googleLogin(googleResponse?.params?.id_token ?? '');
        }
    }, [googleResponse]);

    async function _googleLogin(idToken: string) {
        try {
            if (!idToken) return;
            const res = await api.googleLogin({ idToken });
            await authService.storeCredentials(res);
            setUser(res.user);
        } catch (err) {
            console.log(err);
        }
    }

    // LOGOUT
    const logout = () => {
        queryClient.clear();
        setUser(null);
        return authService.clearCredentials();
    };

    // Check for logged-in user on initial render
    useEffect(() => {
        const checkForUser = async () => {
            const user = await authService.getUser();
            const accessToken = await authService.getAccessToken();
            if (user && accessToken) {
                setUser(user);
            }
        };
        checkForUser();
    }, []);

    return (
        <AuthContext.Provider
            value={{
                user,
                login,
                appleLogin,
                promptGoogleLogin,
                logout,
            }}
            {...props}
        />
    );
}

const useAuth = () => useContext(AuthContext);

export { AuthProvider, useAuth };
