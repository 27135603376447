import { Ionicons } from '@expo/vector-icons';
import {
    Text,
    ButtonProps as DefaultButtonProps,
    StyleSheet,
    ViewStyle,
    GestureResponderEvent,
    Pressable,
} from 'react-native';
import Colors from '../constants/Colors';
interface ButtonProps extends DefaultButtonProps {
    style?: ViewStyle;
    variant?: 'large' | 'small' | 'fluid';
    color?: 'black' | 'white' | 'orange' | 'lightGray';
    squared?: boolean;
    rightArrow?: boolean;
}

export default function ThemedButton(props: ButtonProps) {
    const bg = {
        black: Colors.theme.black,
        white: Colors.theme.white,
        orange: Colors.theme.orange,
        lightGray: Colors.theme.lightGray,
    };

    const btnStyle: ViewStyle = {
        paddingVertical: 10,
        paddingHorizontal: 24,
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: props.squared ? 5 : 9999,
        backgroundColor: props.disabled
            ? Colors.theme.lightGray
            : props.color
            ? bg[props.color]
            : Colors.theme.orange,
    };

    if (props.variant === 'large') {
        Object.assign(btnStyle, {
            paddingVertical: 10,
            paddingHorizontal: 28,
            fontSize: 16,
            height: 50,
        });
    } else if (props.variant === 'fluid') {
        Object.assign(btnStyle, {
            alignSelf: 'stretch',
            fontSize: 14,
        });
    } else {
        Object.assign(btnStyle, {
            paddingVertical: 5,
            paddingHorizontal: 14,
            fontSize: 14,
            height: 40,
        });
    }

    const onPress = (ev: GestureResponderEvent) => {
        if (props.disabled) {
            return;
        }
        props.onPress?.(ev);
    };

    return (
        <Pressable
            style={StyleSheet.flatten([btnStyle, props.style])}
            onPress={onPress}
        >
            <Text style={{ color: '#fff', fontWeight: '600' }}>
                {props.title}
                {props.rightArrow && (
                    <Ionicons
                        name="chevron-forward"
                        size={12}
                        color={Colors.theme.white}
                    />
                )}
            </Text>
        </Pressable>
    );
}
