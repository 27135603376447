import { useState, useEffect } from 'react';
import { StyleSheet, View, Text, FlatList } from 'react-native';
import Theme from '../../constants/Theme';
import Colors from '../../constants/Colors';
import { useUser } from '../../hooks';
import { api } from '../../api';
import {
    AdminDashboardStackProps,
    AdminDashboardStackScreenProps,
} from '../../navigation/types';
import { useNavigation } from '@react-navigation/native';
import { useForm } from 'react-hook-form';
import CtlTextInput from '../../components/ControlledInputs/CtlTextInput';
import { required } from '../../util/formRules';
import { Applicant } from '../../types';
import { filterSearchResults } from '../../util/helperFunctions';
import Checkbox from 'expo-checkbox';
import ThemedButton from '../../components/ThemedButton';
import ThemedScrollView from '../../components/ThemedScrollView';

type SelectableApplicant = Applicant & { selected: boolean };

export default function AdminManualMatchScreen({
    route,
}: AdminDashboardStackScreenProps<'ManualMatch'>) {
    const {
        control,
        reset,
        watch: watchFormValue,
    } = useForm<{ query: string }>();
    const [errMsg, setErrMsg] = useState('');
    const navigation = useNavigation<AdminDashboardStackProps>();
    const [applicants, setApplicants] = useState<SelectableApplicant[]>([]);
    const [filteredApplicants, setFilteredApplicants] = useState<
        SelectableApplicant[]
    >([]);
    const [selectedApplicants, setSelectedApplicants] = useState<
        SelectableApplicant[]
    >([]);

    useEffect(() => {
        loadApplicants();
    }, []);

    async function loadApplicants() {
        try {
            const existingMatches = await api.getJobMatchesByJob(
                route.params.id,
            );
            if (!existingMatches) {
                throw new Error('Error checking for existing matches');
            }

            const allApplicants = await api.getAllApplicants();
            if (!allApplicants) {
                throw new Error('Error fetching applicants');
            }
            // Filter out applicants that already have a match for this job
            const existingExcluded = allApplicants.filter((applicant) => {
                return !existingMatches.some((match) => {
                    return match.applicant.id === applicant.id;
                });
            });

            const mappedApplicants = existingExcluded.map((applicant) => ({
                ...applicant,
                selected: false,
            }));

            setApplicants(mappedApplicants);
        } catch (err: any) {
            setErrMsg(err.message);
        }
    }

    useEffect(() => {
        setFilteredApplicants(
            filterSearchResults(watchFormValue('query'), applicants, [
                'firstName',
                'lastName',
            ]),
        );
    }, [watchFormValue('query'), applicants]);

    async function submitManualMatch() {
        if (selectedApplicants.length === 0) {
            setErrMsg('Please select at least one applicant.');
            return;
        }
        try {
            if (!route.params.id) return;
            await api
                .manualMatch(route.params.id, selectedApplicants)
                .catch((err) => {
                    throw new Error();
                });

            reset();
            navigation.navigate('AdminDashboard');
        } catch (err) {
            setErrMsg('Error submitting manual match.');
        }
    }

    function handleApplicantCheckbox(
        value: boolean,
        applicant: SelectableApplicant,
    ) {
        const foundApplicantIdx = applicants.findIndex((searchApplicant) => {
            return applicant.id === searchApplicant.id;
        });
        if (foundApplicantIdx === -1) return; // Idx wasn't found

        const newApplicantItem = {
            ...applicants[foundApplicantIdx],
        };

        if (!newApplicantItem) return;

        if (value) {
            newApplicantItem.selected = true;
            const tempCopy = [...applicants];
            tempCopy.splice(foundApplicantIdx, 1, newApplicantItem);
            setApplicants(tempCopy);
            setSelectedApplicants([...selectedApplicants, newApplicantItem]);
            return;
        } else {
            newApplicantItem.selected = false;
            const tempCopy = [...applicants];
            tempCopy.splice(foundApplicantIdx, 1, newApplicantItem);
            setApplicants(tempCopy);
            setSelectedApplicants(
                selectedApplicants.splice(
                    selectedApplicants.indexOf(newApplicantItem),
                    1,
                ),
            );
            return;
        }
    }

    const Item = ({ applicant }: { applicant: SelectableApplicant }) => (
        <View style={styles.applicantListItem}>
            <View style={styles.applicantListItemLeft}>
                <Checkbox
                    value={applicant.selected}
                    onValueChange={(value) =>
                        handleApplicantCheckbox(value, applicant)
                    }
                    color={applicant.selected ? Colors.theme.orange : undefined}
                    style={styles.applicantListItemCheckbox}
                />
            </View>
            <View style={styles.applicantListItemRight}>
                <View style={styles.applicantListItemHeader}>
                    <Text style={styles.applicantListItemTitle}>
                        {applicant.firstName} {applicant.lastName}
                    </Text>
                    <Text style={Theme.linkText}>View Profile</Text>
                </View>
                <Text style={Theme.mutedText}>
                    {`${applicant.address?.city ?? ''}, ${
                        applicant.address?.state ?? ''
                    } `}
                    | Pref. Min Pay Rate: $
                    {`${applicant.jobPreference?.minimumRate ?? ''}`}
                </Text>
            </View>
        </View>
    );

    return (
        <ThemedScrollView>
            <View style={Theme.pageContent}>
                <View style={Theme.headerContainer}>
                    <Text style={Theme.headerText}>Send Job to Candidates</Text>
                </View>
                <View style={Theme.searchContainer}>
                    <CtlTextInput
                        name="query"
                        control={control}
                        rules={required}
                        placeholder="Search"
                    />
                </View>
                <>{errMsg && <Text style={Theme.errMsg}>{errMsg}</Text>}</>
                <View style={styles.applicantListContainer}>
                    {filteredApplicants.length !== 0 && (
                        <>
                            <View style={styles.matchButtonContainer}>
                                <ThemedButton
                                    title="Match to Selected"
                                    color="black"
                                    onPress={submitManualMatch}
                                />
                            </View>
                            <FlatList
                                scrollEnabled={false}
                                data={filteredApplicants}
                                renderItem={({ item: applicant }) => (
                                    <Item applicant={applicant} />
                                )}
                                keyExtractor={(
                                    applicant: SelectableApplicant,
                                ) => applicant.id}
                            />
                        </>
                    )}
                    {filteredApplicants.length === 0 && (
                        <Text style={Theme.mutedText}>
                            No applicants found.
                        </Text>
                    )}
                </View>
            </View>
        </ThemedScrollView>
    );
}

const styles = StyleSheet.create({
    sortByLink: {
        fontSize: 20,
        fontWeight: '500',
        textDecorationLine: 'underline',
    },
    applicantListContainer: {
        width: '100%',
    },
    applicantListItem: {
        display: 'flex',
        flexDirection: 'row',
        height: 75,
        backgroundColor: Colors.theme.white,
        marginTop: 6,
        marginBottom: 20,
        textAlign: 'left',
        justifyContent: 'flex-start',
    },
    applicantListItemTitle: {
        marginTop: 5,
        fontSize: 18,
        fontWeight: '500',
    },
    applicantListItemLeft: {
        display: 'flex',
        flexBasis: '15%',
        justifyContent: 'center',
    },
    applicantListItemRight: {
        display: 'flex',
        justifyContent: 'center',
        flexBasis: '85%',
        flexDirection: 'column',
    },
    applicantListItemHeader: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    applicantListItemCheckbox: {
        marginHorizontal: 10,
        height: 25,
        width: 25,
    },
    matchButtonContainer: {
        marginBottom: 20,
    },
});
